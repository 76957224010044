import Layout from "../Layout";
import styles from "./index.module.scss";

type Props = {
  children: React.ReactNode;
};

const GameLayout = ({ children }: Props) => {
  return (
    <Layout>
      <div className={styles["game-layout-wrapper"]}>
        <img
          alt=""
          src="/images/game-background.webp"
          className={styles["game-layout-wrapper__background"]}
        />
        <div className={styles["game-layout-wrapper__content"]}>{children}</div>
      </div>
    </Layout>
  );
};

export default GameLayout;
