import callApi from ".";
import { CHALLENGE } from "../constants";

const viewPost = async (postId: string) => {
  const { data } = await callApi({
    method: "POST",
    body: { postId },
    endpoint: "/challenge/view-post",
  });

  return data;
};

const reactPost = async (data: {
  postId: string;
  action: CHALLENGE.ReactAction;
  reactionType: CHALLENGE.ReactionType;
}) => {
  const res = await callApi({
    body: data,
    method: "POST",
    endpoint: "/challenge/react-post",
  });

  return res.data;
};

const getLeaderboard = async ({
  to,
  from,
  page = 1,
  company_id,
  pageSize = 20,
  employee_name,
}: {
  to?: string;
  from?: string;
  page?: number;
  pageSize?: number;
  company_id?: string;
  employee_name?: string;
}) => {
  const { data } = await callApi({
    endpoint: "/web/challenge/leaderboard",
    query: { to, from, page, pageSize, company_id, employee_name },
  });

  return data;
};

const shareDailyEmotion = async (data: {
  message?: string;
  emotionType: CHALLENGE.EmotionType;
}) => {
  const res = await callApi({
    body: data,
    method: "POST",
    endpoint: "/challenge/share-daily-emotion",
  });

  return res.data;
};

export { viewPost, reactPost, getLeaderboard, shareDailyEmotion };
