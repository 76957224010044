import { useState } from "react";

import styles from "./index.module.scss";

type Props = {
  images: string[];
};

const ImageSlider = ({ images }: Props) => {
  const [currentImage, setCurrentImage] = useState<number>(0);

  const handleClickNext = () => {
    setCurrentImage(currentImage === images.length - 1 ? 0 : currentImage + 1);
  };

  const handleClickPrevious = () => {
    setCurrentImage(currentImage === 0 ? images.length - 1 : currentImage - 1);
  };

  if (!images.length) return null;

  return (
    <div className={styles["slider"]}>
      {images.length > 1 ? (
        <>
          <div
            onClick={handleClickPrevious}
            className={`${styles["arrow"]} ${styles["left-arrow"]}`}
          />
          <div
            onClick={handleClickNext}
            className={`${styles["arrow"]} ${styles["right-arrow"]}`}
          />
        </>
      ) : null}
      {images.map((item, index) => (
        <div
          key={index}
          className={
            index !== currentImage
              ? `${styles["slide"]}`
              : `${styles["slide"]} ${styles["active"]}`
          }
        >
          {index === currentImage && (
            <img alt="" src={item} className={styles["image"]} />
          )}
        </div>
      ))}
    </div>
  );
};

export default ImageSlider;
