import KPI from "./kpi";
import News from "./news";
import Event from "./event";
import Home1 from "./home/v1";
import Home2 from "./home/v2";
import Portal from "./portal";
import Reward from "./reward";
import Signin from "./signin";
import Contact from "./contact";
import Ranking from "./ranking";
import Question from "./question";
import NotFound from "./not-found";
import NewsDetail from "./news-detail";
import EventParticipant from "./event-participant";

export {
  KPI,
  News,
  Event,
  Home1,
  Home2,
  Portal,
  Reward,
  Signin,
  Contact,
  Ranking,
  NotFound,
  Question,
  NewsDetail,
  EventParticipant,
};
