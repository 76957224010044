enum Type {
  SYSTEM = "SYSTEM",
  PERSONAL = "PERSONAL",
}

enum Context {
  NEW_POST = "NEW_POST",
  REACH_POINT = "REACH_POINT",
  NEW_CHALLENGE = "NEW_CHALLENGE",
  VIEW_LEADER_BOARD = "VIEW_LEADER_BOARD",
}

enum ObjectType {
  POST = "POST",
  POINT = "POINT",
  CHALLENGE = "CHALLENGE",
}

export { Type, Context, ObjectType };
