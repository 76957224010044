import callApi from ".";

const upload = async (body: FormData) => {
  const res = await callApi({
    body,
    method: "POST",
    endpoint: "/upload/client/images",
    baseURL: process.env.REACT_APP_API_FILE_UPLOAD,
    headers: { "Content-Type": "multipart/form-data" },
  });

  return res;
};

export { upload };
