import { useContext } from "react";
import { Link, useLocation } from "react-router-dom";

import styles from "./index.module.scss";
import { AppContext } from "../../contexts";
import { signout } from "../../services/auth";

const NavBar = () => {
  const { pathname } = useLocation();
  const { uiDispatch, accountState } = useContext(AppContext);

  const handleClickSignOut = async () => {
    await signout();

    localStorage.removeItem("accessTokenEmp");
    localStorage.removeItem("refreshTokenEmp");

    window.location.href = "/";
  };

  return (
    <div className={styles["navbar-wrapper"]}>
      <div
        className={styles["navbar-wrapper__overlay"]}
        onClick={() => uiDispatch({ payload: false, type: "U-OPEN_NAVBAR" })}
      />
      <div className={styles["navbar-wrapper__menu"]}>
        <img
          alt=""
          src="/images/navbar-background.png"
          className={styles["menu__background"]}
        />
        <div className={styles["menu__container"]}>
          <div className={styles["account"]}>
            <span>Xin chào, {accountState.name.split(" ")[2]}</span>
            <img alt="" src={accountState.avatar} />
          </div>
          <div className={styles["menu-list"]}>
            {[
              { pathname: "/home", text: "Trang chủ" },
              { text: "ANews", pathname: "/news" },
              // { text: "Trồng KPI", pathname: "/kpi" },
              { text: "AEvents", pathname: "/event" },
              { pathname: "/question", text: "Những điều cần biết" },
              // { text: "AChallenge", pathname: "/challenge" },
              // { text: "ARewards", pathname: "/reward" },
              // { text: "APortal", pathname: "/portal" },
              // { text: "Liên hệ", pathname: "/contact" },
            ].map((item) => (
              <Link
                to={item.pathname}
                key={item.pathname}
                className={
                  pathname.includes(item.pathname)
                    ? styles["item--actived"]
                    : ""
                }
                onClick={() =>
                  uiDispatch({ payload: false, type: "U-OPEN_NAVBAR" })
                }
              >
                {item.text}
              </Link>
            ))}
          </div>
          <div className={styles["logout"]} onClick={handleClickSignOut}>
            <span>Đăng xuất</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
