import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useState, useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { AppContext } from "../../../contexts";
import { getCalendars } from "../../../services/calendar";
import { getSpotlightNews } from "../../../services/news";
import { getLeaderboard } from "../../../services/challenge";
import { Layout, CalendarSwiper, CoreValueSwiper } from "../../../components";

const Home1 = () => {
  const defaultDescriptionEvent =
    "Nếu như ví Appota là một bức tranh thì các sự kiện văn hóa chính là những sắc màu tô điểm thêm cho bức tranh đó thêm phần rực rỡ. Không chỉ đơn giản là hoạt động giải trí, những sự kiện văn hóa tại Appota là nơi thể hiện chất riêng của người nhà A, nơi bùng nổ tinh thần nhiệt huyết của tuổi trẻ, nơi kết nối những mảnh ghép đầy cá tính và còn là nơi lắng đọng những tâm tình. Hãy cùng chúng tớ khám phá, trải nghiệm mọi hoạt động văn hóa đặc sắc tại Appota nhé!";

  const navigate = useNavigate();
  const { setIsGlobalLoading } = useContext(AppContext);

  const [cardHover, setCardHover] = useState("");
  const [monthHover, setMonthHover] = useState("");
  const [monthSelected, setMonthSelected] = useState<any>();

  const { data: calendars, isPending: isCalendarsPending } = useQuery({
    queryKey: ["calendars"],
    queryFn: () => getCalendars(),
  });

  const { data: spotlightNews, isPending: isSpotlightNewsPending } = useQuery({
    queryKey: ["spotlightNews"],
    queryFn: () => getSpotlightNews(),
  });

  useEffect(() => {
    setIsGlobalLoading(isCalendarsPending || isSpotlightNewsPending);
  }, [isCalendarsPending, setIsGlobalLoading, isSpotlightNewsPending]);

  return (
    <Layout>
      <div className={styles["home-v1-wrapper"]}>
        {/* Spotlight */}
        <div className={styles["home-v1-wrapper__hero"]}>
          <div className={styles["hero__content"]}>
            <div
              className={styles["content_title"]}
              onClick={() => navigate(`/news/${spotlightNews?.slug}`)}
            >
              <span>{spotlightNews?.title}</span>
            </div>
            <div className={styles["content_description"]}>
              {spotlightNews?.short_description}
            </div>
            <img
              alt=""
              src="/images/mars.gif"
              className={styles["content__image--1"]}
            />
            <img
              alt=""
              src="/images/mercury.gif"
              className={styles["content__image--2"]}
            />
          </div>
          <div className={styles["hero__image"]}>
            <div className={styles["hero__image--main"]}>
              <svg
                viewBox="0 0 766 700"
                onClick={() => navigate(`/news/${spotlightNews?.slug}`)}
              >
                <mask fill="white" id="path-1-inside-1_687_6384">
                  <path d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z" />
                </mask>
                <path
                  fill="url(#pattern0_687_6384)"
                  d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z"
                />
                <path
                  fill="white"
                  fillOpacity="0.5"
                  mask="url(#path-1-inside-1_687_6384)"
                  d="M132 700L132 696L132 700ZM734 700L734 704L734 700ZM634 -4H32V4H634V-4ZM670 68V32H662V68H670ZM734 96H698V104H734V96ZM770 668V132H762V668H770ZM132 704L734 704L734 696L132 696L132 704ZM96 374V668H104V374H96ZM32 346H68V338H32V346ZM-4 32V310H4V32H-4ZM32 338C16.536 338 4 325.464 4 310H-4C-4 329.882 12.1177 346 32 346V338ZM104 374C104 354.118 87.8823 338 68 338V346C83.464 346 96 358.536 96 374H104ZM132 696C116.536 696 104 683.464 104 668H96C96 687.882 112.118 704 132 704L132 696ZM762 668C762 683.464 749.464 696 734 696L734 704C753.882 704 770 687.882 770 668H762ZM734 104C749.464 104 762 116.536 762 132H770C770 112.118 753.882 96 734 96V104ZM662 68C662 87.8823 678.118 104 698 104V96C682.536 96 670 83.464 670 68H662ZM32 -4C12.1177 -4 -4 12.1178 -4 32H4C4 16.536 16.536 4 32 4V-4ZM634 4C649.464 4 662 16.536 662 32H670C670 12.1177 653.882 -4 634 -4V4Z"
                />
                <defs>
                  <pattern
                    width="1"
                    height="1"
                    id="pattern0_687_6384"
                    patternContentUnits="objectBoundingBox"
                  >
                    <use
                      xlinkHref="#image0_687_6384"
                      transform="matrix(0.000669478 0 0 0.000732601 -0.185546 0)"
                    />
                  </pattern>
                  <image
                    width="2048"
                    height="1365"
                    id="image0_687_6384"
                    xlinkHref={spotlightNews?.thumbnail}
                  />
                </defs>
              </svg>
              <img
                alt=""
                src="/images/hero-bottom-leftcorner-mask.svg"
                className={styles["hero__image--bottom-left-corner"]}
              />
            </div>
            <img
              alt=""
              src="/images/earth.gif"
              className={styles["hero__image--top-right-corner"]}
            />
          </div>
        </div>
        {/* Spotlight */}
        {/* Core values */}
        <div className={styles["home-v1-wrapper__core-values"]}>
          <div className={styles["core-values__title"]}>
            <span>Giá trị cốt lõi của Appota</span>
          </div>
          {/* PC */}
          <div className={styles["core-values__list"]}>
            <div className={`${styles["list__row"]} ${styles["list__row--1"]}`}>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("0")}
                className={`${styles["row__card"]} ${styles["row--1__card"]}`}
              >
                <span className={styles["card__alphabet"]}>I</span>
                <span className={styles["card__value"]}>
                  (Integrity - Chính trực)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-1.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Tại Appota, chính trực được xem là giá trị cốt lõi hàng đầu
                  của doanh nghiệp. Chúng ta cam kết thực hiện nhất quán trong
                  suy nghĩ, lời nói và hành động với người khác. Chúng ta nỗ lực
                  xây dựng một môi trường làm việc đáng tự hào nơi mà mọi người
                  luôn đề cao sự trung thực và tin tưởng lẫn nhau.
                </div>
              </div>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("6")}
                className={`${styles["row__card"]} ${styles["row--1__card"]}`}
              >
                <span className={styles["card__alphabet"]}>A</span>
                <span className={styles["card__value"]}>
                  (Authenticity - Hãy là chính mình)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-7.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Appota khuyến khích nhân viên tự tin thể hiện cá tính riêng,
                  tự do làm điều mình thích một cách đúng đắn và thẳng thắn phản
                  hồi mọi vấn đề mà không phân biệt cấp bậc. Không chỉ là chính
                  mình mà chúng ta luôn nỗ lực không ngừng để trở thành phiên
                  bản tốt nhất của chính mình.
                </div>
              </div>
            </div>
            <div className={`${styles["list__row"]} ${styles["list__row--2"]}`}>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("1")}
                className={`${styles["row__card"]} ${styles["row--2__card"]}`}
              >
                <span className={styles["card__alphabet"]}>A</span>
                <span className={styles["card__value"]}>
                  (Adaptability -Thích nghi)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-2.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Có một thứ không bao giờ thay đổi, đó chính là sự thay đổi.
                  Đối với người Appota, thích nghi với mọi sự thay đổi, sẵn sàng
                  đón nhận mọi thử thách là điều tất yếu. Để chúng ta không ai
                  bị bỏ lại phía sau thì mỗi cá nhân cần không ngừng học hỏi,
                  rèn luyện khả năng sáng tạo, linh hoạt hơn trong mọi hoàn
                  cảnh.
                </div>
              </div>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("5")}
                className={`${styles["row__card"]} ${styles["row--2__card"]}`}
              >
                <span className={styles["card__alphabet"]}>T</span>
                <span className={styles["card__value"]}>
                  (Teamwork - Đồng bộ)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-6.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Sức mạnh đồng bộ thể hiện ở một tập thể cam kết hoạt động vì
                  một mục tiêu chung. Mỗi cá nhân, mỗi phòng ban tại Appota luôn
                  sẵn sàng hợp tác, kết nối, hỗ trợ lẫn nhau nhằm tìm kiếm giải
                  pháp tối ưu và chinh phục mọi thử thách.
                </div>
              </div>
            </div>
            <div className={`${styles["list__row"]} ${styles["list__row--3"]}`}>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("2")}
                className={`${styles["row__card"]} ${styles["row--3__card"]}`}
              >
                <span className={styles["card__alphabet"]}>P</span>
                <span className={styles["card__value"]}>
                  (Proactivity - Chủ động)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-3.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Chủ động là chìa khóa dẫn lối thành công, quyết định tương lai
                  của mỗi người. Tại Appota, mỗi thành viên luôn được trao quyền
                  để làm chủ trong chính công việc của mình. Chúng ta không chờ
                  đợi cơ hội đến với mình mà chúng ta chủ động nắm bắt cơ hội và
                  tự tạo ra cơ hội cho chính bản thân.
                </div>
              </div>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("4")}
                className={`${styles["row__card"]} ${styles["row--3__card"]}`}
              >
                <span className={styles["card__alphabet"]}>O</span>
                <span className={styles["card__value"]}>
                  (Owership - Máu lửa)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-5.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Máu lửa trong từng suy nghĩ và hành động vừa là bệ phóng vững
                  chắc để mọi nhân sự khai phá năng lực bản thân, vừa là kim chỉ
                  nam giúp cho doanh nghiệp phát triển một cách toàn diện, bền
                  vững. Mỗi thành viên của Appota đều được khuyến khích làm mọi
                  thứ dựa trên tinh thần sở hữu, máu lửa và chủ động.
                </div>
              </div>
            </div>
            <div className={`${styles["list__row"]} ${styles["list__row--4"]}`}>
              <div
                onMouseOut={() => setCardHover("")}
                onMouseOver={() => setCardHover("3")}
                className={`${styles["row__card"]} ${styles["row--4__card"]}`}
              >
                <span className={styles["card__alphabet"]}>P</span>
                <span className={styles["card__value"]}>
                  (Positivity - Tích cực)
                </span>
                <img
                  alt=""
                  src="/images/tho-than-4.png"
                  className={styles["card__image"]}
                />
                <div className={styles["card__description"]}>
                  Appota luôn hướng tới một môi trường làm việc tích cực cho
                  nhân sự. Đối mặt với những thử thách thay vì việc than vãn, đổ
                  lỗi, buông xuôi thì chúng ta hãy suy nghĩ và hành động một
                  cách lạc quan nhất. Học cách lan tỏa những giá trị tốt đẹp đến
                  mọi người sẽ khiến cho bản thân tốt hơn mỗi ngày.
                </div>
              </div>
            </div>
          </div>
          {/* PC */}
          {/* Mobile */}
          <div className={styles["core-values__list-mobile"]}>
            <CoreValueSwiper setCardHover={setCardHover} />
          </div>
          {/* Mobile */}
          <div className={styles["core-values__text"]}>
            {[
              { key: "0", text: "I" },
              { key: "1", text: "A" },
              { key: "2", text: "P" },
              { key: "3", text: "P" },
              { key: "4", text: "O" },
              { key: "5", text: "T" },
              { key: "6", text: "A" },
            ].map((item) => (
              <span
                key={item.key}
                className={`${
                  cardHover === item.key ? styles["text__active"] : ""
                }`}
              >
                {item.text}
              </span>
            ))}
          </div>
        </div>
        {/* Core values */}
        {/* Events */}
        <div className={styles["home-v1-wrapper__events"]}>
          <div className={styles["events__box"]}>
            <div className={styles["box__title"]}>
              Chuỗi sự kiện văn hoá {new Date().getFullYear()}
            </div>
            <div className={styles["box__content"]}>
              <div className={styles["content__left"]}>
                <div className={styles["left__text"]}>
                  {monthSelected ? (
                    <div className={styles["text__month"]}>
                      <span>{monthSelected ? monthSelected.subject : ""}</span>
                    </div>
                  ) : null}
                  <span className={styles["text__title"]}>
                    {monthSelected
                      ? monthSelected.events.find((item: any) => item.isActive)
                          ?.title || ""
                      : "Giới thiệu sự kiện văn hóa"}
                  </span>
                  <div className={styles["text__description"]}>
                    {monthSelected
                      ? monthSelected.events.find((item: any) => item.isActive)
                          ?.description || defaultDescriptionEvent
                      : defaultDescriptionEvent}
                  </div>
                </div>
                <img alt="" src="/images/tho-than.svg" />
              </div>
              {/* PC */}
              <div className={styles["content__right"]}>
                <div className={styles["right--top"]}>
                  <div className={styles["right--top__inner"]}></div>
                </div>
                <div className={styles["right--bottom"]}>
                  <div className={styles["right--bottom__inner"]}></div>
                </div>
                <div
                  className={`${styles["right__line"]} ${styles["right__line-1"]}`}
                >
                  {calendars?.slice(0, 4).map((item: any) => (
                    <div
                      key={item.id}
                      onMouseOut={() => setMonthHover("")}
                      onClick={() => setMonthSelected(item)}
                      onMouseOver={() => setMonthHover(item.id)}
                      className={`${styles["right__line__item"]} ${styles["right__line-1__item"]}`}
                    >
                      <span
                        className={`${styles["item__month"]} ${
                          [monthHover, monthSelected?.id].includes(item.id)
                            ? `${styles["item__month--hover-text"]} ${styles["item__month--hover-border"]}`
                            : ""
                        }`}
                      >
                        T{item.id}
                      </span>
                      <div className={styles["item-1__events"]}>
                        {item.content
                          .slice(0, 3)
                          .map((element: string, index: number) => (
                            <span
                              key={index}
                              className={`${styles["event--text"]} ${
                                [monthHover, monthSelected?.id].includes(
                                  item.id
                                )
                                  ? styles["item__month--hover-text"]
                                  : ""
                              }`}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`${styles["right__line"]} ${styles["right__line-2"]}`}
                >
                  {calendars?.slice(4, 5).map((item: any) => (
                    <div
                      key={item.id}
                      onMouseOut={() => setMonthHover("")}
                      onClick={() => setMonthSelected(item)}
                      onMouseOver={() => setMonthHover(item.id)}
                      className={`${styles["right__line__item"]} ${styles["right__line-2__item"]}`}
                    >
                      <span
                        className={`${styles["item__month"]} ${
                          [monthHover, monthSelected?.id].includes(item.id)
                            ? `${styles["item__month--hover-text"]} ${styles["item__month--hover-border"]}`
                            : ""
                        }`}
                      >
                        T{item.id}
                      </span>
                      <div className={styles["item-2__events"]}>
                        {item.content
                          .slice(0, 3)
                          .map((element: string, index: number) => (
                            <span
                              key={index}
                              className={`${styles["event--text"]} ${
                                [monthHover, monthSelected?.id].includes(
                                  item.id
                                )
                                  ? styles["item__month--hover-text"]
                                  : ""
                              }`}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`${styles["right__line"]} ${styles["right__line-3"]}`}
                >
                  {calendars?.slice(5, 8).map((item: any) => (
                    <div
                      key={item.id}
                      onMouseOut={() => setMonthHover("")}
                      onClick={() => setMonthSelected(item)}
                      onMouseOver={() => setMonthHover(item.id)}
                      className={`${styles["right__line__item"]} ${styles["right__line-3__item"]}`}
                    >
                      <span
                        className={`${styles["item__month"]} ${
                          [monthHover, monthSelected?.id].includes(item.id)
                            ? `${styles["item__month--hover-text"]} ${styles["item__month--hover-border"]}`
                            : ""
                        }`}
                      >
                        T{item.id}
                      </span>
                      <div className={styles["item-3__events"]}>
                        {item.content
                          .slice(0, 3)
                          .map((element: string, index: number) => (
                            <span
                              key={index}
                              className={`${styles["event--text"]} ${
                                [monthHover, monthSelected?.id].includes(
                                  item.id
                                )
                                  ? styles["item__month--hover-text"]
                                  : ""
                              }`}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`${styles["right__line"]} ${styles["right__line-4"]}`}
                >
                  {calendars?.slice(8, 9).map((item: any) => (
                    <div
                      key={item.id}
                      onMouseOut={() => setMonthHover("")}
                      onClick={() => setMonthSelected(item)}
                      onMouseOver={() => setMonthHover(item.id)}
                      className={`${styles["right__line__item"]} ${styles["right__line-4__item"]}`}
                    >
                      <span
                        className={`${styles["item__month"]} ${
                          [monthHover, monthSelected?.id].includes(item.id)
                            ? `${styles["item__month--hover-text"]} ${styles["item__month--hover-border"]}`
                            : ""
                        }`}
                      >
                        T{item.id}
                      </span>
                      <div className={styles["item-4__events"]}>
                        {item.content
                          .slice(0, 3)
                          .map((element: string, index: number) => (
                            <span
                              key={index}
                              className={`${styles["event--text"]} ${
                                [monthHover, monthSelected?.id].includes(
                                  item.id
                                )
                                  ? styles["item__month--hover-text"]
                                  : ""
                              }`}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className={`${styles["right__line"]} ${styles["right__line-5"]}`}
                >
                  {calendars?.slice(9, 12).map((item: any) => (
                    <div
                      key={item.id}
                      onMouseOut={() => setMonthHover("")}
                      onClick={() => setMonthSelected(item)}
                      onMouseOver={() => setMonthHover(item.id)}
                      className={`${styles["right__line__item"]} ${styles["right__line-5__item"]}`}
                    >
                      <span
                        className={`${styles["item__month"]} ${
                          [monthHover, monthSelected?.id].includes(item.id)
                            ? `${styles["item__month--hover-text"]} ${styles["item__month--hover-border"]}`
                            : ""
                        }`}
                      >
                        T{item.id}
                      </span>
                      <div className={styles["item-5__events"]}>
                        {item.content
                          .slice(0, 3)
                          .map((element: string, index: number) => (
                            <span
                              key={index}
                              className={`${styles["event--text"]} ${
                                [monthHover, monthSelected?.id].includes(
                                  item.id
                                )
                                  ? styles["item__month--hover-text"]
                                  : ""
                              }`}
                            >
                              {element}
                            </span>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* PC */}
              {/* Mobile */}
              <div className={styles["content__right-mobile"]}>
                <div className={styles["right__line"]} />
                <CalendarSwiper
                  calendars={calendars}
                  setMonthSelected={setMonthSelected}
                />
              </div>
              {/* Mobile */}
            </div>
          </div>
          <div className={styles["events__background"]}>
            <img alt="" src="/images/pond.svg" />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--1"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--2"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--3"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--4"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--5"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--6"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--7"]}`}
            />
            <div
              className={`${styles["background__shape"]} ${styles["background__shape--8"]}`}
            />
          </div>
        </div>
        {/* Events */}
        {/* Rankings */}
        <Rankings />
        {/* Rankings */}
      </div>
    </Layout>
  );
};

const Rankings = () => {
  const navigate = useNavigate();

  const [searchValue, setSearchValue] = useState("");

  const { data: leaderboard } = useQuery({
    queryKey: ["leaderboard", searchValue],
    queryFn: () =>
      getLeaderboard({ page: 1, pageSize: 10, employee_name: searchValue }),
  });

  const handleSearchEmployee = debounce((e: any) => {
    setSearchValue(e.target.value);
  }, 1000);

  return (
    <div className={styles["home-v1-wrapper__rankings"]}>
      <div className={styles["rankings__over-background"]}>
        <img alt="" src="/images/pond-1.svg" />
      </div>
      <div className={styles["rankings__title"]}>
        <img
          alt=""
          src="/images/leaves-left.svg"
          className={styles["title__icon-1"]}
        />
        <span>Bảng xếp hạng {new Date().getFullYear()}</span>
        <img
          alt=""
          src="/images/leaves-right.svg"
          className={styles["title__icon-2"]}
        />
      </div>
      <div className={styles["rankings__box"]}>
        <div className={styles["box__image"]}>
          <img alt="" src="/images/tho-than-8.png" />
        </div>
        <div className={styles["box__content"]}>
          <div className={styles["box__content-1"]}>
            <div className={styles["content-1__search"]}>
              <img alt="" src="/images/magnifying-glass-icon.svg" />
              <input
                onChange={handleSearchEmployee}
                placeholder="Tìm kiếm nhân viên"
              />
            </div>
            <div
              onClick={() => navigate("/ranking")}
              className={styles["content-1__expand"]}
            >
              <span className={styles["expand__text"]}>Xem chi tiết</span>
              <img alt="" src="/images/double-arrow-right.svg" />
            </div>
          </div>
          <div className={styles["box__content-2"]}>
            {leaderboard?.data.map((item: any, index: number) => (
              <div key={index} className={styles["content-2__item"]}>
                <div className={styles["item__avatar"]}>
                  <img
                    alt=""
                    src={item.employee_avatar}
                    className={styles["avatar__image"]}
                  />
                  {parseInt(item.index) === 1 ? (
                    <img
                      alt=""
                      src="/images/crown-icon.svg"
                      className={styles["avatar__icon"]}
                    />
                  ) : null}
                </div>
                <div className={styles["item__info"]}>
                  <span
                    className={`${styles["info__name"]} ${
                      parseInt(item.index) < 4 ? styles["info__name-top-3"] : ""
                    }`}
                  >
                    {item.employee_name}
                  </span>
                  <div className={styles["info__position"]}>
                    <div className={styles["position__department"]} />
                    <span>{item.company_name}</span>
                  </div>
                </div>
                <span className={styles["item__point"]}>
                  {item.total_points}pts
                </span>
                <span
                  className={`${styles["item__rank"]} ${
                    parseInt(item.index) < 4 ? styles["item__rank-top-3"] : ""
                  }`}
                >
                  #{item.index}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home1;
