import "swiper/css";
import moment from "moment";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useRef, useState, useEffect, useContext } from "react";
import { useQuery, useInfiniteQuery } from "@tanstack/react-query";

import {
  getEvent,
  getEventParticipants,
  reactEventParticipant,
} from "../../services/event";
import styles from "./index.module.scss";
import { Layout } from "../../components";
import { AppContext } from "../../contexts";
import { EventUploadModal } from "../../components/Modals";

const EventParticipant = () => {
  const { id }: any = useParams();
  const { setIsGlobalLoading } = useContext(AppContext);

  const searchRef = useRef<HTMLInputElement>(null);
  const [isShowCloseIcon, setIsShowCloseIcon] = useState<boolean>(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false);

  const { data: eventDetail, isPending: isEventDetailPending } = useQuery({
    enabled: Boolean(id),
    queryKey: ["event", id],
    queryFn: () => getEvent(id),
  });

  const { data, refetch, isPending, hasNextPage, fetchNextPage } =
    useInfiniteQuery({
      initialPageParam: 1,
      enabled: Boolean(id),
      queryKey: ["event-participants", id],
      queryFn: ({ pageParam = 1 }) =>
        getEventParticipants({
          eventId: id,
          page: pageParam,
          participant: searchRef.current?.value,
        }),
      getNextPageParam: (lastPage: any) =>
        lastPage.hasNextPage ? lastPage.page + 1 : undefined,
    });

  const handleClickLike = async ({
    reaction,
    participant_id,
  }: {
    participant_id: string;
    reaction: "like" | "dislike";
  }) => {
    await reactEventParticipant({ reaction, event_id: id, participant_id });
    refetch();
  };

  useEffect(() => {
    setIsGlobalLoading(isPending || isEventDetailPending);
  }, [isPending, setIsGlobalLoading, isEventDetailPending]);

  return (
    <Layout>
      <div className={styles["event-participant-wrapper"]}>
        <div className={styles["event-participant-wrapper__content"]}>
          <div className={styles["content__header"]}>
            <span className={styles["content__title"]}>
              {eventDetail?.title}
            </span>
            {eventDetail?.is_joined ||
            !moment().isBetween(
              eventDetail?.start_date,
              eventDetail?.end_date
            ) ? null : (
              <div
                className={styles["button__join"]}
                onClick={() => setIsOpenUploadModal(true)}
              >
                <svg width="24" height="24">
                  <path
                    fill="#0E2FDB"
                    d="M7.80562 14.4974L5.7173 13.5L3.95418 17.8539C3.94869 17.8676 3.94338 17.8815 3.9388 17.8954C3.71486 18.5673 3.76466 19.1777 4.07924 19.6139C4.35188 19.9924 4.79024 20.2009 5.31355 20.2009H5.31374C5.56148 20.2009 5.82771 20.1541 6.10494 20.0616C6.11903 20.057 6.13295 20.0519 6.1465 20.0464L10.5004 18.2833L9.50319 16.1948C9.23641 15.6365 8.36391 14.764 7.80562 14.4974Z"
                  />
                  <path
                    fill="#0E2FDB"
                    d="M23.9399 1.05469L10.2305 14.7642C10.4581 15.0445 10.6476 15.3283 10.7721 15.5887L14.252 22.8755C14.7195 23.8544 15.3358 24.0005 15.6614 24.0005H15.6616C15.9525 24.0005 16.6712 23.874 17.0621 22.7014L23.8607 2.30585C24.013 1.84845 24.0383 1.41962 23.9399 1.05469Z"
                  />
                  <path
                    fill="#0E2FDB"
                    d="M9.23618 13.7693L22.9464 0.0591431C22.8026 0.0201416 22.6485 0 22.4861 0C22.2379 0 21.9717 0.046875 21.6945 0.139343L1.29911 6.93768C0.220065 7.29749 0.0234092 7.92554 0.00216899 8.26776C-0.0190712 8.61017 0.0986656 9.25763 1.12497 9.74762L8.41184 13.2277C8.67203 13.3522 8.95584 13.5417 9.23618 13.7693Z"
                  />
                </svg>
                <span>Tham gia sự kiện</span>
              </div>
            )}
          </div>
          <div className={styles["content__box"]}>
            <div className={styles["box__filter"]}>
              <div className={styles["filter__field--input"]}>
                <img
                  alt=""
                  src="/images/magnifying-glass-icon.svg"
                  className={styles["search__magnifying-icon"]}
                />
                <input
                  ref={searchRef}
                  placeholder="Tìm kiếm người đăng"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") refetch();
                  }}
                  onChange={(e) => setIsShowCloseIcon(Boolean(e.target.value))}
                />
                {isShowCloseIcon ? (
                  <img
                    alt=""
                    src="/images/close-icon.svg"
                    onClick={() => {
                      searchRef.current!.value = "";
                      setIsShowCloseIcon(false);
                      refetch();
                    }}
                    className={styles["search__close-icon"]}
                  />
                ) : null}
              </div>
            </div>
            <div className={styles["box__list"]}>
              {data?.pages
                .reduce((acc: any, curr: any) => [...acc, ...curr.data], [])
                .map((item: any) => (
                  <div key={item.id} className={styles["box__item"]}>
                    <div className={styles["item__info"]}>
                      <img
                        alt=""
                        src={item.employee.avatar}
                        className={styles["info__image"]}
                      />
                      <div className={styles["info__text"]}>
                        <span className={styles["text__name"]}>
                          {item.employee.name}
                        </span>
                        <span className={styles["text__time"]}>
                          {moment(item.createdAt).format("hh:mm, DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                    <div className={styles["item__description"]}>
                      {item.comment}
                    </div>
                    <div className={styles["item__images"]}>
                      <Swiper
                        slidesPerView={1}
                        modules={[Pagination]}
                        style={{ height: "100%" }}
                        pagination={{ clickable: true }}
                      >
                        {item.images.map((element: any, index: any) => (
                          <SwiperSlide key={index}>
                            <div className={styles["image"]}>
                              <img
                                alt=""
                                src={`${process.env.REACT_APP_FILE_URL}/${element.path}`}
                              />
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div className={styles["item__react"]}>
                      <span>{item.total_react} lượt thích</span>
                      <svg
                        width="24"
                        height="24"
                        style={{ cursor: "pointer", marginLeft: "12px" }}
                        onClick={() =>
                          handleClickLike({
                            participant_id: item.id,
                            reaction: item.is_reacted ? "dislike" : "like",
                          })
                        }
                      >
                        <path
                          fill={item.is_reacted ? "#FD3A3A" : "#D8D8D8"}
                          d="M16.9459 3.81177C12.5495 3.81177 11.9994 7.46009 11.9994 7.46009C11.9994 7.46009 11.4514 3.81177 7.05497 3.81177C2.82659 3.81177 1.59448 8.25617 2.11052 10.6324C3.47065 16.9129 11.9994 21.3133 11.9994 21.3133C11.9994 21.3133 20.5302 16.9129 21.8903 10.6324C22.4043 8.25617 21.1702 3.81177 16.9459 3.81177Z"
                        />
                      </svg>
                    </div>
                  </div>
                ))}
            </div>
            {hasNextPage && (
              <div
                onClick={() => fetchNextPage()}
                className={styles["box__expand"]}
              >
                <span>Xem thêm</span>
                <img alt="" src="/images/double-arrow-down.svg" />
              </div>
            )}
          </div>
        </div>
      </div>
      <EventUploadModal
        event={eventDetail}
        isOpen={isOpenUploadModal}
        onClose={() => setIsOpenUploadModal(false)}
      />
    </Layout>
  );
};

export default EventParticipant;
