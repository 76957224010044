import Footer from "./Footer";
import Header from "./Header";
import Layout from "./Layout";
import Comment from "./Comment";
import Loading from "./Loading";
import SpinWheel from "./SpinWheel";
import GameLayout from "./GameLayout";
import ImageSlider from "./ImageSlider";
import PrivateRoute from "./PrivateRoute";
import CalendarSwiper from "./CalendarSwiper";
import Congratulation from "./Congratulation";
import CoreValueSwiper from "./CoreValueSwiper";
import NotificationPopup from "./NotificationPopup";

export {
  Footer,
  Header,
  Layout,
  Comment,
  Loading,
  SpinWheel,
  GameLayout,
  ImageSlider,
  PrivateRoute,
  CalendarSwiper,
  Congratulation,
  CoreValueSwiper,
  NotificationPopup,
};
