import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import styles from "./index.module.scss";
import Loading from "../../components/Loading";
import { getToken, getSigninURL } from "../../services/auth";

const Signin = () => {
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);

  const handleClickSignin = async () => {
    setIsLoading(true);
    const res = await getSigninURL();

    if (res?.login_web) window.location.href = res.login_web;
  };

  useEffect(() => {
    (async () => {
      const acheckinToken = searchParams.get("token");

      if (acheckinToken) {
        setIsLoading(true);

        const data = await getToken(acheckinToken).catch((err) => {
          setIsLoading(false);
          toast.error(err.message, { toastId: "signin-error" });
        });

        if (data?.accessToken && data?.refreshToken) {
          localStorage.setItem("accessTokenEmp", data.accessToken);
          localStorage.setItem("refreshTokenEmp", data.refreshToken);

          window.location.href = "/home";
        }
      }
    })();
  }, [searchParams]);

  if (isLoading) return <Loading />;

  return (
    <div className={styles["signin-wrapper"]}>
      <div className={styles["signin-wrapper__header"]}>
        <img alt="" src={"/images/appota-inside-logo.svg"} />
        <div
          onClick={() => handleClickSignin()}
          className={styles["header__button"]}
        >
          <svg viewBox="0 0 208 56">
            <path
              fill="url(#paint0_linear_1_928)"
              stroke="url(#paint1_linear_1_928)"
              d="M0.5 8C0.5 3.85786 3.85786 0.5 8 0.5H187.005C188.979 0.5 190.873 1.27801 192.277 2.66534L205.272 15.5069C206.698 16.9159 207.5 18.837 207.5 20.8415V48C207.5 52.1421 204.142 55.5 200 55.5H8.00001C3.85787 55.5 0.5 52.1421 0.5 48V8Z"
            />
            <defs>
              <linearGradient
                y1="18.9"
                y2="59.0355"
                x2="204.099"
                x1="-5.32717e-09"
                id="paint0_linear_1_928"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#E2FF6C" />
                <stop offset="1" stopColor="#00FFFF" />
              </linearGradient>
              <linearGradient
                y1="28.0002"
                y2="28.0002"
                x2="208.003"
                x1="-0.00442749"
                id="paint1_linear_1_928"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4383FF" />
                <stop offset="1" stopColor="#0029FF" />
              </linearGradient>
            </defs>
          </svg>
          <div className={styles["button__content"]}>
            <img alt="" src={"/images/email-icon.svg"} />
            <span>Đăng nhập</span>
          </div>
        </div>
      </div>
      <div className={styles["signin-wrapper__info"]}>
        <div className={styles["info__title"]}>
          <span>We are</span>
          <div className={styles["title__icon"]}>
            <img
              alt=""
              src={"/images/archer-boy.png"}
              className={styles["title__icon--boy"]}
            />
            <img
              alt=""
              src={"/images/archer-girl.png"}
              className={styles["title__icon--girl"]}
            />
          </div>
          <span>Appota</span>
        </div>
        <div className={styles["info__description"]}>
          Inside Appota là cổng thông tin nội bộ của Appota Group. Chỉ các thành
          viên có tài khoản email nội bộ của Appota hoặc tài khoản AD do bộ phận
          Nhân sự cung cấp mới có thể truy cập vào hệ thống này.
        </div>
      </div>
      <div className={styles["signin-wrapper__intro"]}>
        <div className={styles["intro-1"]}>
          <div className={styles["intro-2"]}>
            <video loop muted autoPlay>
              <source src="https://cdn.pixabay.com/video/2024/06/08/215769.mp4" />
            </video>
          </div>
        </div>
      </div>
      <div className={styles["signin-wrapper__background-gif"]}>
        <img alt="" src={"/images/mars.gif"} />
      </div>
    </div>
  );
};

export default Signin;
