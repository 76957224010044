import { useQuery } from "@tanstack/react-query";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect, useContext } from "react";

import SpinWheel from "../SpinWheel";
import styles from "./index.module.scss";
import { EmployeeModal } from "../Modals";
import { AppContext } from "../../contexts";
import Congratulation from "../Congratulation";
import { getProfile } from "../../services/account";
import NotificationPopup from "../NotificationPopup";
import { NOTIFCATION_ACTION } from "../../contexts/notification";
import { getUnreadNotificationCount } from "../../services/notification";

const Header = () => {
  const {
    uiDispatch,
    accountState,
    accountDispatch,
    notificationState,
    notificationDispatch,
  } = useContext(AppContext);
  const { pathname } = useLocation();

  const [gift, setGift] = useState("");
  const [isOpenNotiPopup, setIsOpenNotiPopup] = useState(false);
  const [isOpenSpinWheel, setIsOpenSpinWheel] = useState(false);
  const [isOpenEmployeeModal, setIsOpenEmployeeModal] = useState(false);

  const { data: profile } = useQuery({
    queryKey: ["profile"],
    queryFn: () => getProfile(),
  });

  const { data: totalUnreadNotification } = useQuery({
    queryKey: ["total-unread-notification"],
    queryFn: () => getUnreadNotificationCount(),
  });

  useEffect(() => {
    profile && accountDispatch({ payload: profile, type: "S-ACCOUNT" });

    totalUnreadNotification &&
      notificationDispatch({
        type: NOTIFCATION_ACTION.UPDATE_UNREAD,
        payload: { totalUnread: totalUnreadNotification.total },
      });
  }, [profile, accountDispatch, totalUnreadNotification, notificationDispatch]);

  return (
    <div className={styles["header-wrapper"]}>
      <div className={styles["header-wrapper__points"]}>
        <div className={styles["points__left-side"]}>
          <div className={styles["left-side__icon"]}>
            <img alt="" src={"/images/medal.svg"} />
          </div>
          <div className={styles["left-side__detail"]}>
            <span>
              Điểm tích lũy: {accountState.total_points.toLocaleString()}
            </span>
            <div className={styles["detail__progress"]}>
              <div
                style={{
                  width: `${
                    accountState.total_points > 10000
                      ? 100
                      : accountState.total_points / 100
                  }%`,
                }}
                className={styles["detail__progress--active"]}
              />
            </div>
            <span className={styles["detail__target-points"]}>
              <span>10,000</span>
              <span style={{ marginLeft: "16px" }}>
                Tích điểm hàng ngày, nhận thưởng cuối năm
              </span>
            </span>
          </div>
        </div>
        <div
          onClick={() => setIsOpenSpinWheel(true)}
          className={`${styles["points__right-side"]} ${
            accountState.total_points > 9999
              ? styles["points__right-side--active"]
              : ""
          }`}
        >
          <span>Đổi điểm lấy quà</span>
        </div>
      </div>
      <div className={styles["header-wrapper__menu"]}>
        <img
          alt=""
          className={styles["menu_logo"]}
          src={"/images/appota-inside-logo.svg"}
        />
        <div className={styles["menu_middle"]}>
          {[
            { pathname: "/home", text: "Trang chủ" },
            { text: "ANews", pathname: "/news" },
            // { text: "Trồng KPI", pathname: "/kpi" },
            { text: "AEvents", pathname: "/event" },
            { pathname: "/question", text: "Những điều cần biết" },
            // { text: "AChallenge", pathname: "/challenge" },
            // { text: "ARewards", pathname: "/reward" },
            // { text: "APortal", pathname: "/portal" },
            // { text: "Liên hệ", pathname: "/contact" },
          ].map((item) => (
            <Link
              to={item.pathname}
              key={item.pathname}
              className={
                pathname.includes(item.pathname)
                  ? styles["menu_middle--active"]
                  : ""
              }
            >
              {item.text}
            </Link>
          ))}
        </div>
        <div
          className={styles["menu__button"]}
          onClick={() => uiDispatch({ payload: true, type: "U-OPEN_NAVBAR" })}
        >
          <svg width="24" fill="none" height="24" viewBox="0 0 24 24">
            <path d="M22 4H2" stroke="white" strokeWidth="1.5" />
            <path d="M22 12L8 12" stroke="white" strokeWidth="1.5" />
            <path d="M22 20H15" stroke="white" strokeWidth="1.5" />
          </svg>
        </div>
        <div className={styles["menu_right"]}>
          <div className={styles["menu_right__notification"]}>
            <div
              className={styles["notification__icon"]}
              onClick={() => setIsOpenNotiPopup(!isOpenNotiPopup)}
            >
              <img alt="" src={"/images/ring.svg"} />
              {!!notificationState.totalUnread && (
                <div className={styles["unread__decorator"]}>
                  <div className={styles["unread__inner"]}></div>
                </div>
              )}
            </div>
            <NotificationPopup
              isOpen={isOpenNotiPopup}
              openPointPopup={() => setIsOpenSpinWheel(true)}
            />
          </div>
          <div
            className={styles["menu_right__account"]}
            onClick={() => setIsOpenEmployeeModal(true)}
          >
            <div className={styles["account_avatar"]}>
              <img alt="" src={accountState.avatar} />
            </div>
            <div className={styles["account_name"]}>
              Xin chào, {accountState.name.split(" ")[2]}
            </div>
            <div className={styles["account_expand"]}>
              <img alt="" src={"/images/arrow-down.svg"} />
            </div>
          </div>
        </div>
      </div>
      <Congratulation
        gift={gift}
        isOpen={Boolean(gift)}
        onClose={() => setGift("")}
      />
      <SpinWheel
        setGift={setGift}
        isOpen={isOpenSpinWheel}
        onClose={() => setIsOpenSpinWheel(false)}
      />
      <EmployeeModal
        isOpen={isOpenEmployeeModal}
        onClose={() => setIsOpenEmployeeModal(false)}
      />
    </div>
  );
};

export default Header;
