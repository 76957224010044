import { useState } from "react";

import styles from "./index.module.scss";
import { Layout } from "../../components";

const Reward = () => {
  const [pizeHover, setPizeHover] = useState("");

  return (
    <Layout>
      <div className={styles["reward-wrapper"]}>
        <div className={styles["reward-wrapper__content"]}>
          <span className={styles["content__title"]}>Cơ cấu giải thưởng</span>
          <div className={styles["content__pizes"]}>
            <div className={`${styles["pizes"]} ${styles["pizes__month"]}`}>
              <span className={styles["item__title"]}>Giải tháng</span>
              <div className={styles["pizes__month__item-group"]}>
                <div
                  className={styles["pizes__item"]}
                  onMouseOut={() => setPizeHover("")}
                  onMouseOver={() => setPizeHover("1")}
                >
                  <img
                    alt=""
                    className={styles["item__image"]}
                    src={`/images/${
                      pizeHover === "1" ? "prize-1-open.png" : "prize-1.png"
                    }`}
                  />
                  <span className={styles["item__text"]}>Thổ Quý Quân</span>
                </div>
                <div
                  className={styles["pizes__item"]}
                  onMouseOut={() => setPizeHover("")}
                  onMouseOver={() => setPizeHover("2")}
                >
                  <img
                    alt=""
                    className={styles["item__image"]}
                    src={`/images/${
                      pizeHover === "2" ? "prize-2-open.png" : "prize-2.png"
                    }`}
                  />
                  <span className={styles["item__text"]}>Thổ Á Quân</span>
                </div>
                <div
                  className={styles["pizes__item"]}
                  onMouseOut={() => setPizeHover("")}
                  onMouseOver={() => setPizeHover("3")}
                >
                  <img
                    alt=""
                    className={styles["item__image"]}
                    src={`/images/${
                      pizeHover === "3" ? "prize-3-open.png" : "prize-3.png"
                    }`}
                  />
                  <span className={styles["item__text"]}>Thổ Quán Quân</span>
                </div>
              </div>
            </div>
            <div className={`${styles["pizes"]} ${styles["pizes__year"]}`}>
              <span className={styles["item__title"]}>Giải năm</span>
              <div className={styles["pizes__year__item-group"]}>
                <div
                  className={styles["pizes__item"]}
                  onMouseOut={() => setPizeHover("")}
                  onMouseOver={() => setPizeHover("4")}
                >
                  <img
                    alt=""
                    src="/images/prize-4.png"
                    className={styles["item__image"]}
                  />
                  {pizeHover === "4" ? (
                    <div className={styles["item__background"]} />
                  ) : null}
                  <span className={styles["item__text"]}>
                    Top 7 Thổ Tinh Tú
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={styles["content__mission"]}>
            <span className={styles["mission__title"]}>Cơ cấu giải thưởng</span>
            <div className={styles["mission__group"]}>
              {[
                {
                  name: "Chia sẻ cảm xúc mỗi ngày",
                  description: "+200 điểm/lần share đầu tiên",
                },
                {
                  name: "Đọc tin",
                  description: "50 điểm/lần xem đầu tiên/tin",
                },
                {
                  name: "Thả cảm xúc",
                  description: "50 điểm/lần thả cảm xúc",
                },
                {
                  name: "Bình luận",
                  description: "50 điểm/bình luận hợp lệ",
                },
                {
                  name: "Tham gia challenge",
                  description: "300 điểm/challenge",
                },
                {
                  name: "Chiến thắng challenge",
                  description: "500 điểm/challenge",
                },
                {
                  name: "Top đi làm sớm",
                  description: "10 điểm/ngày",
                },
                {
                  name: "Tham gia Fest Event",
                  description: "500 điểm/sự kiện",
                },
                {
                  name: "Tham gia Core Event",
                  description: "1000 điểm/sự kiện",
                },
                {
                  name: "Hoàn thành survey sự kiện",
                  description: "100 điểm/survey",
                },
                {
                  name: "Lan tỏa sự kiện",
                  description: "200 điểm/sự kiện",
                },
                {
                  name: "Đóng góp sự kiện",
                  description: "300 điểm/sự kiện",
                },
              ].map((item, index) => (
                <div key={index} className={styles["mission__item"]}>
                  <img
                    alt=""
                    className={styles["image__corner"]}
                    src="/images/news-mask-right-corner.svg"
                  />
                  <div className={styles["item__content"]}>
                    <span>{item.name}</span>
                    <span className={styles["content__description"]}>
                      {item.description}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Reward;
