import { useEffect } from "react";

import styles from "./index.module.scss";

type Props = {
  gift: string;
  isOpen?: boolean;
  onClose: () => void;
};

const Congratulations = ({ gift, onClose, isOpen = false }: Props) => {
  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["congratulations-wrapper"]}>
      {Array.from(Array(500).keys()).map((item, index) => (
        <div key={index} className={styles["confetti-" + item]} />
      ))}
      <div className={styles["congratulations-wrapper__modal"]}>
        <img
          alt=""
          className={styles["modal__background"]}
          src="/images/congratulations-background-modal.svg"
        />
        <div onClick={onClose} className={styles["main__close"]}>
          <img alt="" src="/images/close-icon.svg" />
        </div>
        <div className={styles["modal__content"]}>
          <span className={styles["content__point"]}>{gift}</span>
          <span className={styles["content__description"]}>
            Bộ phận Văn Hoá Doanh nghiệp sẽ liên hệ để trao quà cho bạn.
            <br />
            <strong>Đợi chút nhé</strong>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Congratulations;
