import styles from "./index.module.scss";
import { GameLayout } from "../../components";

const KPI = () => {
  return (
    <GameLayout>
      <div className={styles[""]}></div>
    </GameLayout>
  );
};

export default KPI;
