import { toast } from "react-toastify";
import { useState, useEffect } from "react";

import styles from "./index.module.scss";
import ImageSlider from "../../ImageSlider";
import { upload } from "../../../services/file";
import { useScreenDetector } from "../../../hooks";
import { joinEvent } from "../../../services/event";

type Props = {
  event: any;
  isOpen?: boolean;
  onClose: () => void;
  invalidateQuery?: string;
};

const EventUpload = ({ event, onClose, isOpen = false }: Props) => {
  const [comment, setComment] = useState<string>("");
  const [isLoading, setIsLoading] = useState<string>("");
  const [imagesURL, setImagesURL] = useState<string[]>([]);
  const [imagesPreview, setImagesPreview] = useState<string[]>([]);

  const { isMobile } = useScreenDetector();

  const handleChangeFile = async (e: any) => {
    const { files } = e.target;

    if (files.length > 5) {
      toast.info("Tối đa 5 ảnh", { toastId: "maximumImages" });

      return;
    }

    try {
      const filesURL = [];
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        formData.append("files", files[i]);
        filesURL.push(URL.createObjectURL(files[i]));
      }

      setIsLoading("upload-images");

      const images = await upload(formData);

      setIsLoading("");
      setImagesPreview([...filesURL, ...imagesPreview]);
      setImagesURL([
        ...imagesURL,
        ...images.map((item: { id: string }) => item.id),
      ]);
    } catch (err: any) {
      setIsLoading("");

      toast.error(err.message, { toastId: "uploadFile" });
    }
  };

  const handleClickClose = () => {
    onClose();
  };

  const handleClickSubmit = async () => {
    try {
      if (!imagesURL.length) {
        toast.info("Chia sẻ một vài hình ảnh nhé", {
          autoClose: 5000,
          hideProgressBar: true,
          toastId: "requireImage",
        });

        return;
      }

      if (comment.length < 30) {
        toast.info("Tối thiểu 30 ký tự", {
          autoClose: 5000,
          hideProgressBar: true,
          toastId: "requireComment",
        });

        return;
      }

      setIsLoading("join-event");

      await joinEvent(event.id, { comment, images: imagesURL });

      setIsLoading("");

      onClose();
    } catch (err: any) {
      setIsLoading("");

      toast.error(err.message, { toastId: "joinEvent" });
    }
  };

  useEffect(() => {
    setComment("");
    setIsLoading("");
    setImagesURL([]);
    setImagesPreview([]);

    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal-wrapper__main"]}>
        <div className={styles["main__background"]}>
          <svg
            viewBox="0 0 800 810"
            width={isMobile ? 350 : 700}
            height={isMobile ? 355 : 710}
          >
            <path
              fill="white"
              d="M0 40C0 17.9086 17.9086 0 40 0H660C682.091 0 700 17.9086 700 40V60C700 82.0914 717.909 100 740 100H760C782.091 100 800 117.909 800 140V770C800 792.091 782.091 810 760 810H108C88.1178 810 72 793.882 72 774C72 754.118 55.8823 738 36 738C16.1178 738 0 721.882 0 702V40Z"
            />
          </svg>
          <div
            onClick={handleClickClose}
            className={styles["background__close"]}
          >
            <img alt="" src="/images/close-icon.svg" />
          </div>
        </div>
        <div className={styles["main__content"]}>
          <div className={styles["content__wrapper"]}>
            <div className={styles["content__event-name"]}>
              Tham gia sự kiện <br />
              {event.title}
            </div>
            <div className={styles["content__upload"]}>
              {isLoading === "upload-images" ? (
                <div className={styles["loader"]} />
              ) : imagesPreview.length ? (
                <>
                  <ImageSlider images={imagesPreview} />
                  <div className={styles["upload__input"]}>
                    <label
                      htmlFor="file-upload"
                      className={styles["custom-file-upload"]}
                    >
                      Thêm ảnh
                    </label>
                    <input
                      multiple
                      type="file"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleChangeFile}
                    />
                  </div>
                </>
              ) : (
                <>
                  <label
                    htmlFor="file-upload"
                    className={styles["upload__image"]}
                  >
                    <img alt="" src="/images/upload-icon.svg" />
                  </label>
                  <div className={styles["upload__input"]}>
                    <label
                      htmlFor="file-upload"
                      className={styles["custom-file-upload"]}
                    >
                      Chọn ảnh
                    </label>
                    <input
                      multiple
                      type="file"
                      accept="image/*"
                      id="file-upload"
                      onChange={handleChangeFile}
                    />
                  </div>
                </>
              )}
            </div>
            <div className={styles["content__text"]}>
              <textarea
                maxLength={500}
                placeholder="Chia sẻ cảm xúc của bạn"
                onChange={(e) => setComment(e.target.value)}
              />
              <div className={styles["input__length"]}>
                {comment.length}/500
              </div>
            </div>
            <div className={styles["content__footer"]}>
              {isLoading === "join-event" ? (
                <span className={styles["loader"]} />
              ) : (
                <div
                  onClick={handleClickSubmit}
                  className={styles["footer__button"]}
                >
                  Gửi ngay
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventUpload;
