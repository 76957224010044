// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CoreValueSwiper_mobile__card__\\+N9Qw {
  height: 450px;
  display: flex;
  margin: 0px 10%;
  color: #ffffff;
  padding: 15px 30px;
  align-items: center;
  border-radius: 24px;
  flex-direction: column;
  background: linear-gradient(135deg, #4383ff, #0029ff);
}
.CoreValueSwiper_mobile__card__\\+N9Qw .CoreValueSwiper_card__alphabet__Hn6NN {
  font-size: 48px;
  font-weight: bold;
}
.CoreValueSwiper_mobile__card__\\+N9Qw .CoreValueSwiper_card__value__MUVtc {
  font-size: 14px;
  font-weight: bold;
}
.CoreValueSwiper_mobile__card__\\+N9Qw .CoreValueSwiper_card__image__S9r1w {
  width: 120px;
  margin: 10% 0px;
}
.CoreValueSwiper_mobile__card__\\+N9Qw .CoreValueSwiper_card__description__3ft7j {
  font-size: 14px;
  overflow: scroll;
  line-height: 150%;
  overflow-x: hidden;
  text-align: justify;
}
.CoreValueSwiper_mobile__card__\\+N9Qw .CoreValueSwiper_card__description__3ft7j::-webkit-scrollbar {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/components/CoreValueSwiper/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,eAAA;EACA,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,qDAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;AACJ;AAEE;EACE,eAAA;EACA,iBAAA;AAAJ;AAGE;EACE,YAAA;EACA,eAAA;AADJ;AAIE;EACE,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,mBAAA;AAFJ;AAII;EACE,aAAA;AAFN","sourcesContent":[".mobile__card {\n  height: 450px;\n  display: flex;\n  margin: 0px 10%;\n  color: #ffffff;\n  padding: 15px 30px;\n  align-items: center;\n  border-radius: 24px;\n  flex-direction: column;\n  background: linear-gradient(135deg, #4383ff, #0029ff);\n\n  .card__alphabet {\n    font-size: 48px;\n    font-weight: bold;\n  }\n\n  .card__value {\n    font-size: 14px;\n    font-weight: bold;\n  }\n\n  .card__image {\n    width: 120px;\n    margin: 10% 0px;\n  }\n\n  .card__description {\n    font-size: 14px;\n    overflow: scroll;\n    line-height: 150%;\n    overflow-x: hidden;\n    text-align: justify;\n\n    &::-webkit-scrollbar {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mobile__card": `CoreValueSwiper_mobile__card__+N9Qw`,
	"card__alphabet": `CoreValueSwiper_card__alphabet__Hn6NN`,
	"card__value": `CoreValueSwiper_card__value__MUVtc`,
	"card__image": `CoreValueSwiper_card__image__S9r1w`,
	"card__description": `CoreValueSwiper_card__description__3ft7j`
};
export default ___CSS_LOADER_EXPORT___;
