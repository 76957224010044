import { TAction } from ".";

interface IUIState {
  isOpenNavbar: boolean;
}

const initialUIState: IUIState = {
  isOpenNavbar: false,
};

const UIReducer = (state: IUIState, action: TAction) => {
  const { type, payload } = action;

  switch (type) {
    case "U-OPEN_NAVBAR":
      return { ...state, isOpenNavbar: payload };

    default:
      throw new Error(`Action type ${type} is undefined`);
  }
};

export { UIReducer, initialUIState };
