// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GameLayout_game-layout-wrapper__kGYfJ {
  display: flex;
  margin-top: 75px;
  position: relative;
  justify-content: center;
}
.GameLayout_game-layout-wrapper__kGYfJ .GameLayout_game-layout-wrapper__background__8DG\\+3 {
  width: 75%;
  object-fit: contain;
}
.GameLayout_game-layout-wrapper__kGYfJ .GameLayout_game-layout-wrapper__content__KyfdH {
  width: 75%;
  height: 100%;
  padding: 24px;
  position: absolute;
  box-sizing: border-box;
}

@media screen and (max-width: 425px) {
  .GameLayout_game-layout-wrapper__kGYfJ {
    margin-top: 48px;
  }
  .GameLayout_game-layout-wrapper__kGYfJ .GameLayout_game-layout-wrapper__background__8DG\\+3 {
    width: 90%;
  }
  .GameLayout_game-layout-wrapper__kGYfJ .GameLayout_game-layout-wrapper__content__KyfdH {
    width: 90%;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/GameLayout/index.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,gBAAA;EACA,kBAAA;EACA,uBAAA;AACF;AACE;EACE,UAAA;EACA,mBAAA;AACJ;AAEE;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,kBAAA;EACA,sBAAA;AAAJ;;AAIA;EACE;IACE,gBAAA;EADF;EAGE;IACE,UAAA;EADJ;EAIE;IACE,UAAA;EAFJ;AACF","sourcesContent":[".game-layout-wrapper {\n  display: flex;\n  margin-top: 75px;\n  position: relative;\n  justify-content: center;\n\n  .game-layout-wrapper__background {\n    width: 75%;\n    object-fit: contain;\n  }\n\n  .game-layout-wrapper__content {\n    width: 75%;\n    height: 100%;\n    padding: 24px;\n    position: absolute;\n    box-sizing: border-box;\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .game-layout-wrapper {\n    margin-top: 48px;\n\n    .game-layout-wrapper__background {\n      width: 90%;\n    }\n\n    .game-layout-wrapper__content {\n      width: 90%;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"game-layout-wrapper": `GameLayout_game-layout-wrapper__kGYfJ`,
	"game-layout-wrapper__background": `GameLayout_game-layout-wrapper__background__8DG+3`,
	"game-layout-wrapper__content": `GameLayout_game-layout-wrapper__content__KyfdH`
};
export default ___CSS_LOADER_EXPORT___;
