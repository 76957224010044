import { useState, useReducer } from "react";
import { Route, Routes, Navigate, BrowserRouter } from "react-router-dom";

import {
  // KPI,
  News,
  Event,
  Home2,
  // Portal,
  // Reward,
  Signin,
  // Contact,
  // Ranking,
  NotFound,
  Question,
  NewsDetail,
  EventParticipant,
} from "./pages";
import {
  NotificationReducer,
  initialNotificationState,
} from "./contexts/notification";
import { AppContext } from "./contexts";
import { PrivateRoute } from "./components";
import { UIReducer, initialUIState } from "./contexts/ui";
import { AccountReducer, initialAccountState } from "./contexts/account";

const App = () => {
  const token = localStorage.getItem("accessTokenEmp");

  const [accountState, accountDispatch] = useReducer(
    AccountReducer,
    initialAccountState
  );

  const [notificationState, notificationDispatch] = useReducer(
    NotificationReducer,
    initialNotificationState
  );

  const [isGlobalLoading, setIsGlobalLoading] = useState(false);

  const [uiState, uiDispatch] = useReducer(UIReducer, initialUIState);

  return (
    <AppContext.Provider
      value={{
        uiState,
        uiDispatch,

        accountState,
        accountDispatch,

        isGlobalLoading,
        setIsGlobalLoading,

        notificationState,
        notificationDispatch,
      }}
    >
      <BrowserRouter>
        <Routes>
          <Route element={<PrivateRoute />}>
            {/* <Route path="/kpi" element={<KPI />} /> */}
            <Route path="/news" element={<News />} />
            <Route path="/home" element={<Home2 />} />
            <Route path="/event" element={<Event />} />
            {/* <Route path="/portal" element={<Portal />} /> */}
            {/* <Route path="/reward" element={<Reward />} /> */}
            {/* <Route path="/contact" element={<Contact />} /> */}
            {/* <Route path="/ranking" element={<Ranking />} /> */}
            <Route path="/question" element={<Question />} />
            <Route path="/news/:slug" element={<NewsDetail />} />
            <Route path="/event/:id" element={<EventParticipant />} />
          </Route>
          <Route
            path="/"
            element={token ? <Navigate to="/home" /> : <Signin />}
          />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </BrowserRouter>
    </AppContext.Provider>
  );
};

export default App;
