import { toast } from "react-toastify";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState, useEffect } from "react";

import styles from "./index.module.scss";
import { useScreenDetector } from "../../hooks";
import { getGifts, spinWheel } from "../../services/gift";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
  setGift: (gift: string) => void;
};

const SpinWheel = ({ onClose, setGift, isOpen = false }: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [gifts, setGifts] = useState<string[]>([]);
  const [isAllowSpin, setIsAllowSpin] = useState<boolean>(true);

  const { isMobile } = useScreenDetector();

  const { data } = useQuery({ queryKey: ["gifts"], queryFn: () => getGifts() });

  let speed: number = 0;
  let currentDeg: number = 0;
  let pause: boolean = false;
  let maxRotation: number =
    Math.floor(Math.random() * (360 * 6 - 360 * 3 + 1)) + 360 * 3;
  let itemDegs: {
    [key in (typeof gifts)[number]]: { endDeg: number; startDeg: number };
  } = {};

  const toRad = (deg: number) => deg * (Math.PI / 180.0);

  const drawWheel = () => {
    if (!canvasRef.current) return;

    const ctx = canvasRef.current.getContext("2d");
    if (!ctx) return;

    let startDeg = currentDeg;
    const centerX = canvasRef.current.width / 2;
    const centerY = canvasRef.current.height / 2;

    for (let i = 0; i < gifts.length; i++, startDeg += 360 / gifts.length) {
      const endDeg = startDeg + 360 / gifts.length;
      const color = { b: 255, g: i % 2 ? 255 : 220, r: i % 2 ? 255 : 206 };

      ctx.beginPath();
      ctx.arc(centerX, centerY, centerX - 2, toRad(startDeg), toRad(endDeg));
      ctx.lineTo(centerX, centerY);
      ctx.fillStyle = "#0025E4";
      ctx.fill();

      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        centerX - (isMobile ? 7.5 : 10),
        toRad(startDeg),
        toRad(endDeg)
      );
      ctx.lineTo(centerX, centerY);
      ctx.fillStyle = `rgb(${color.r},${color.g},${color.b})`;
      ctx.fill();

      ctx.save();
      ctx.fillStyle = "#0E2FDB";
      ctx.font = `bold ${isMobile ? 8 : 12}px Manrope`;
      ctx.translate(centerX, centerY);
      ctx.rotate(toRad((startDeg + endDeg) / 2));
      ctx.fillText(gifts[i], isMobile ? 50 : 100, isMobile ? 1 : 2);
      ctx.restore();

      itemDegs[gifts[i]] = { endDeg: endDeg, startDeg: startDeg };
    }
  };

  const animate = () => {
    if (pause) return;

    const percent =
      ((currentDeg - maxRotation) * 100) / (0 - maxRotation) / 100;

    speed = Math.sin((percent * Math.PI) / 2) * 20;

    if (speed < 0.01) {
      speed = 0;
      pause = true;
    }

    currentDeg += speed;

    drawWheel();

    window.requestAnimationFrame(animate);
  };

  const spin = async () => {
    try {
      if (speed !== 0) return;

      currentDeg = 0;
      maxRotation = 0;

      drawWheel();

      const result = await spinWheel();

      setTimeout(() => {
        onClose();
        setGift(result.name);
      }, 8000);

      maxRotation = 360 * 6 - itemDegs[result.name].endDeg + 25;

      itemDegs = {};
      pause = false;

      window.requestAnimationFrame(animate);
    } catch (err: any) {
      setIsAllowSpin(false);
      toast.error(err.message, { toastId: "allowToSpin" });
    }
  };

  useEffect(() => drawWheel());

  useEffect(() => {
    data && setGifts(data.map((item: any) => item.name));
  }, [data]);

  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["spin-wheel-wrapper"]}>
      <div className={styles["spin-wheel-wrapper__modal"]}>
        {isAllowSpin ? (
          <div className={styles["modal__title-1"]}>
            <img
              alt=""
              src="/images/speaker.svg"
              className={styles["title__icon"]}
            />
            <span>Quay để thử vận may của bạn</span>
          </div>
        ) : (
          <div className={styles["modal__title-2"]}>
            <img
              alt=""
              src="/images/speaker-1.svg"
              className={styles["title__icon"]}
            />
            <span>
              Bạn chưa đủ điều kiện để quay! Kiếm điểm để lọt top 10 nhé.
            </span>
          </div>
        )}
        <div className={styles["modal__spinner"]}>
          <div className={styles["wheel"]}>
            <canvas
              ref={canvasRef}
              width={isMobile ? 300 : 484}
              height={isMobile ? 300 : 484}
            />
            <div onClick={spin} className={styles["center-circle"]}>
              <span>Spin</span>
              <div className={styles["triangle"]} />
            </div>
          </div>
        </div>
        <img
          alt=""
          onClick={onClose}
          src="/images/close-outline-icon.svg"
          className={styles["modal__spinner__close"]}
        />
      </div>
    </div>
  );
};

export default SpinWheel;
