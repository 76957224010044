import { useContext } from "react";

import Footer from "../Footer";
import Header from "../Header";
import NavBar from "../NavBar";
import { FeelingModal } from "../Modals";
import styles from "./index.module.scss";
import { Loading } from "../../components";
import { AppContext } from "../../contexts";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const { uiState, isGlobalLoading } = useContext(AppContext);

  if (isGlobalLoading) return <Loading />;

  return (
    <div className={styles["layout-wrapper"]}>
      <Header />
      {children}
      <div
        className={styles["layout-wrapper__gmail"]}
        onClick={() =>
          window.open("https://mail.google.com/mail/u/0/#inbox?compose=new")
        }
      >
        <img alt="" src="/images/gmail.svg" />
        <span>Liên hệ</span>
      </div>
      <Footer />
      <FeelingModal />
      {uiState.isOpenNavbar ? <NavBar /> : null}
    </div>
  );
};

export default Layout;
