import { useState, useEffect } from "react";

const useScreenDetector = () => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleChangeWindowSize = () => setWidth(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", handleChangeWindowSize);

    return () => window.removeEventListener("resize", handleChangeWindowSize);
  }, []);

  return {
    // isSmallMobile: width <= 320,
    // isMediumMobile: width > 320 && width <= 375,
    // isLargeMobile: width > 375 && width <= 425,
    // isTablet: width > 425 && width <= 768,
    // isLaptop: width > 768 && width <= 1024,
    // isLargeLaptop: width > 1024 && width <= 1440,
    // is4k: width > 1440,
    isMobile: width <= 425,
    isTablet: width <= 768,
    isLaptop: width > 1024,
  };
};

export default useScreenDetector;
