import { useState } from "react";
import { toast } from "react-toastify";

import styles from "./index.module.scss";
import { submitContact } from "../../../services/contact";

const ContactModal = () => {
  const [issues, setIssues] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleClickSend = async () => {
    if (!issues || (issues && issues.trim() === "")) {
      toast.error("Nội dung rỗng", { toastId: "contact-empty" });
      return;
    }
    if (issues.length < 30) {
      toast.error("Tối thiểu 30 ký tự", { toastId: "contact-min-length" });
      return;
    }

    setIsLoading(true);

    try {
      const { result } = await submitContact(issues);

      if (result) {
        setIsLoading(false);
        toast.info("VHDN đã tiếp nhận", { toastId: "contact-success" });
      }
    } catch (err: any) {
      setIsLoading(false);
      toast.error(err.message, { toastId: "contact-error" });
    }
  };

  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal-wrapper__main"]}>
        <div className={styles["main__background"]}>
          <svg viewBox="0 0 894 640">
            <path
              fill="white"
              d="M0 35C0 15.67 15.67 0 35 0H759C778.33 0 794 15.67 794 35V65C794 84.33 809.67 100 829 100H859C878.33 100 894 115.67 894 135V605C894 624.33 878.33 640 859 640H135C115.67 640 100 624.33 100 605V575C100 555.67 84.33 540 65 540H35C15.67 540 0 524.33 0 505V35Z"
            />
          </svg>
        </div>
        <div className={styles["main__content"]}>
          <div className={styles["content__title"]}>
            <span>Hãy liên hệ với VHDN nếu bạn cần hỗ trợ</span>
          </div>
          <div className={styles["content__field"]}>
            <div className={styles["field__label"]}>
              <img
                alt=""
                className={styles["label__image"]}
                src="/images/main-image-contact-modal.webp"
              />
              <div className={styles["label__content"]}>
                <div className={styles["content__email"]}>
                  <img alt="" src="/images/letter-icon.svg" />
                  <span>prnb@appota.com</span>
                </div>
                <div className={styles["content__address"]}>
                  <img alt="" src="/images/location-icon.svg" />
                  <span>
                    Tầng 6, toà Moon, số 16, ngõ 71 Láng Hạ, Thành Công, Ba
                    Đình, Hà Nội
                  </span>
                </div>
              </div>
            </div>
            <div className={styles["field__input"]}>
              <textarea
                maxLength={500}
                placeholder="Chia sẻ vấn đề của bạn"
                onChange={(e) => setIssues(e.target.value)}
              />
              <div className={styles["input__length"]}>{issues.length}/500</div>
            </div>
          </div>
          <div className={styles["content__button"]}>
            <button disabled={isLoading} onClick={handleClickSend}>
              Gửi ngay
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactModal;
