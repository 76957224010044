import { useState } from "react";

import styles from "./index.module.scss";
import { Layout } from "../../components";

const Question = () => {
  const leftQuestions = [
    {
      key: "1",
      question: `Nếu muốn hỏi đáp về chính sách, chế độ, đãi ngộ, phúc lợi, ... thì sẽ liên hệ với phòng ban nào?`,
      answer: `Tất cả những vấn đề liên quan tới chính sách, chế độ đãi ngộ, lương thưởng, phúc lợi của CBNV, bạn vui lòng liên hệ với bộ phận C&B hoặc Ms. Huyền Nghiêm - C&B Manager để được hỗ trợ chi tiết.`,
    },
    {
      key: "2",
      question: `Appota có những khoản hỗ trợ chi phí nào cho nhân viên?`,
      answer: `Đối với mỗi chức vụ của nhân viên, Appota sẽ có những khoản chi phí khác nhau, cụ thể:<br />
      - Nhân viên chính thức:<br />
      + Phụ cấp gửi xe: 200.000 vnd/tháng<br />
      + Phụ cấp laptop: 200.000 vnd/tháng (Nếu không sử dụng laptop của công ty)<br />
      - Cộng tác viên, thực tập sinh<br />
      + Phụ cấp gửi xe: 200.000 vnd/tháng`,
    },
    {
      key: "3",
      question: `Những đãi ngộ, chính sách tại Appota áp dụng cho những đối tượng nào?`,
      answer: `Đối với mỗi chức vụ của nhân viên, Appota sẽ có những chế độ đãi ngộ, chính sách khác nhau, cụ thể bạn vui lòng xem <a target="_blank" style="text-decoration: none" href=""><strong style="cursor: pointer">TẠI ĐÂY</strong></a>`,
    },
    {
      key: "4",
      question: `Nếu muốn xin hỗ trợ chi phí để tham gia các khóa học cải thiện năng lực thì tôi cần liên hệ với bộ phận nào?`,
      answer: `Những vấn đề về học tập, cải thiện năng lực chuyên môn bạn vui lòng liên hệ với bộ phận L&D Team qua email daotao@appota.com hoặc chị Lưu Ánh để được hỗ trợ.`,
    },
    {
      key: "5",
      question: `Mua đồ uống tại Appota Cafe có phải trả phí không?`,
      answer: `Appota Cafe được sử dụng với mục đích chính là phục vụ cho nội bộ nhân viên Appota và có tính phí tuỳ theo mỗi loại đồ uống.`,
    },
    {
      key: "6",
      question: `Có bao nhiêu khung thời gian làm việc tại Appota?`,
      answer: `- Hiện tại Appota đang có 02 khung giờ làm việc: Khung từ 08:00 đến 17:30 hoặc Khung từ 08:30 đến 18:00<br />
      - Thời gian làm việc: Từ thứ 2 - thứ 6 và thứ 7 cách tuần (Riêng thứ 7 chỉ cần làm việc đến 16:00)`,
    },
    {
      key: "7",
      question: `Quy trình đặt phòng họp?`,
      answer: `Để có thể đặt phòng họp tại Appota, bạn vui lòng thực hiện theo các bước:<br />
      - Bước 1: Truy cập app ACheckin, lựa chọn phần "Lịch họp"<br />
      - Bước 2: Click vào biểu tượng "+" ở góc bên phải phía cuối màn hình<br />
      - Bước 3: Điền các thông tin cần thiết về cuộc họp (Thời gian, chọn phòng họp, tên cuộc họp...)<br />
      - Bước 4: Chọn "Tiếp tục" và mời các thành viên tham gia cuộc họp và hoàn tất<br />
      Lưu ý: Mỗi lần đặt lịch họp bạn chỉ có thể đặt tối đa 02 tiếng`,
    },
    {
      key: "8",
      question: `Quy trình gửi đơn bù công và xác nhận chấm công hàng tháng như thế nào?`,
      answer: `Thông thường để có thể xác nhận chấm công và đánh giá dự án sẽ được thực hiện trong khoảng từ ngày 23 đến ngày 25 hàng tháng. Quy trình gửi đơn bù công và kiểm tra ngày công bạn vui lòng xem <a target="_blank" style="text-decoration: none" href="https://docs.google.com/document/d/1-8wnE3nkydwv95VEuZkAN_6B3izanmnlpAXUnLBZMZc/edit"><strong style="cursor: pointer">TẠI ĐÂY</strong></a> Mọi thắc mắc vui lòng liên hệ bộ phận C&B hoặc Ms. Huyền Nghiêm để được hỗ trợ`,
    },
    {
      key: "9",
      question: `Cách đăng ký ăn sáng như thế nào?`,
      answer: `Để đăng ký ăn sáng tại Appota bạn vui lòng thực hiện theo các bước sau đây:<br />
      - Bước 1: Truy cập app ACheckin, chọn phần "Wifi Cafe"<br />
      - Bước 2: Lựa chọn nơi ăn sáng mà bạn mong muốn<br />
      - Bước 3: Chọn mã khuyến mãi có sẵn tại phần "Chọn mã khuyến mãi" <br />
      - Bước 4: Ấn "Đặt mua" và bạn có thể thưởng thức bữa sáng hoàn toàn miễn phí tại Appota<br />
      Lưu ý: Appota chỉ phục vụ đồ ăn sáng trong trước 08:30 sáng hàng ngày`,
    },
    {
      key: "10",
      question: `Cách đăng ký và đăng nhập vào ACheckin?`,
      answer: `Để đăng ký/ đăng nhập vào app ACheckin, bạn vui lòng xem chi tiết <a target="_blank" style="text-decoration: none" href="https://help.acheckin.io/huong-dan-dung-ung-dung-acheckin/tham-gia-workspace"><strong style="cursor: pointer">TẠI ĐÂY</strong></a>`,
    },
    {
      key: "11",
      question: `Tôi có thể xem tài liệu về pháp chế, quy trình triển khai hợp đồng ở đâu?`,
      answer: `Để xem quy trình làm việc về hợp đồng, pháp chế, bạn vui lòng xem <a target="_blank" style="text-decoration: none" href=""><strong style="cursor: pointer">TẠI ĐÂY</strong></a>`,
    },
  ];
  const rightQuestions = [
    {
      key: "12",
      question: `Quy trình order thiết kế ấn phẩm, quay phim, chụp ảnh như thế nào?`,
      answer: `Để order media hay thiết kế ấn phẩm, bạn vui lòng liên hệ với Ms. Tú Phương tại tầng 06 toà Moon để được hỗ trợ`,
    },
    {
      key: "13",
      question: `Quy trình giới thiệu ứng viên cho tuyển dụng?`,
      answer: `Để giới thiệu ứng viên, bạn vui lòng liên hệ với bộ phận Tuyển dụng hoặc Ms. Huyền Đỗ tại tầng 03 toà Moon để được hỗ trợ`,
    },
    {
      key: "14",
      question: `Một năm Appota có những sự kiện chính nào bắt buộc phải tham gia?`,
      answer: `Hàng năm, Appota có 03 sự kiện chính cần sự tham gia đông đủ của các thành viên tuỳ thuộc vào đối tượng phù hợp của từng sự kiện:<br />
      <strong>1. Thổ Thần Giao Tranh</strong><br />
      + Đối tượng: Tất cả Nhân viên, CTV, TTS làm việc tại Appota<br />
      + Mô tả: Đây là sự kiện hội thao được tổ chức thường niên vào tháng 06 hoặc tháng 07 hàng năm<br />
      <strong>2. Xứng Danh Thủ Lĩnh</strong><br />
      + Đối tượng: Leader, BOD, Chuyên gia<br />
      + Mô tả: Hoạt động leader outing dành riêng cho đội ngũ lãnh đạo tại Appota diễn ra vào tháng 09 hàng năm<br />
      <strong>3. Huyền Thoại Thổ</strong><br />
      + Đối tượng: Toàn thể nhân viên chính thức của Appota<br />
      + Mô tả: Sự kiện sinh nhật công ty kết hợp du lịch nghỉ dưỡng diễn ra vào tháng 12 hàng năm `,
    },
    {
      key: "15",
      question: `Những ai có thể tham gia sự kiện Sinh nhật công ty cuối năm?`,
      answer: `Sự kiện Sinh nhật công ty diễn ra vào cuối năm chỉ dành cho nhân viên chính thức tại Appota. Tuy nhiên, các bạn CTV hoặc TTS có thành tích xuất sắc sẽ được xét duyệt để tham gia sự kiện này.`,
    },
    {
      key: "16",
      question: `Khi nào tôi sẽ được vào nhóm facebook Inside Appota?`,
      answer: `Sau khi trở thành nhân viên chính thức, bạn sẽ được quản trị viên thêm vào nhóm facebook Inside Appota`,
    },
    {
      key: "17",
      question: `Nếu cần hỗ trợ về hệ thống mạng, internet thì sẽ liên hệ với ai?`,
      answer: `Để được hỗ trợ các vấn đề liên quan tới hệ thống mạng, internet... bạn vui lòng liên hệ Mr. Bùi Lập tại tầng 05 toà LE để được hỗ trợ.`,
    },
    {
      key: "18",
      question: `Làm thế nào để góp ý, feedback về các sự kiện tại Appota?`,
      answer: `Sau mỗi sự kiện tại Appota, BTC của chương trình sẽ có form khảo sát ý kiến đánh giá gửi tới mọi người. Bạn vui lòng điền form đánh giá để phản hồi ý kiến sau mỗi sự kiện nhé.`,
    },
    {
      key: "19",
      question: `Đội ngũ BODs bao gồm những ai?`,
      answer: `Đội ngũ BODs của Appota bao gồm:<br />
      1. Mr. Đỗ Tuấn Anh - Founder/ CEO Appota<br />
      2. Mr. Trần Vinh Quang - Giám đốc vận hành<br />
      3. Ms. Nguyễn Thuỳ Liên - Giám đốc đầu tư<br />
      4. Ms. Nguyễn Thị Thuý Nga - Giám đốc tài chính<br />
      5. Ms. Bùi Thị Hằng - Giám đốc nhân sự<br />
      6. Ms. Vũ Thị Trang - CEO Gamota<br />
      7. Mr. Phạm Bá Duy - CEO OTA Network<br />
      8. Mr. Trần Quốc Toản - CEO Wallacy<br />
      9. Mr. Đào Tuấn Anh - CEO AppotaPay<br />
      10. Mr. Đặng Phú Vinh - CEO Adsota<br />
      11. Mr. Đào Hồng Điệp - CEO Kdata`,
    },
    {
      key: "20",
      question: `Tôi có thể liên hệ với ai để được hỗ trợ về cơ sở vật chất?`,
      answer: `Để được hỗ trợ về cơ sở vật chất khi làm việc, bạn vui lòng liên hệ tới bộ phận hành chính tại tầng 03 toà Moon để được hỗ trợ`,
    },
    {
      key: "21",
      question: `Có thể đưa người ngoài công ty vào sử dụng dịch vụ tại Appota Cafe không?`,
      answer: `Hiện tại, Appota Cafe chỉ phục vụ tại quầy cho nội bộ nhân viên Appota và phục vụ take away đối với khách hàng bên ngoài.`,
    },
  ];

  const [questionsSelected, setQuestionsSelected] = useState<string[]>([]);

  return (
    <Layout>
      <div className={styles["question-wrapper"]}>
        <div className={styles["question-wrapper__content"]}>
          <span className={styles["content__title"]}>Những điều cần biết</span>
          <div className={styles["content__box"]}>
            <div className={styles["box__left"]}>
              {leftQuestions.map((item) => (
                <QuestionItem
                  item={item}
                  key={item.key}
                  questionsSelected={questionsSelected}
                  setQuestionsSelected={setQuestionsSelected}
                />
              ))}
            </div>
            <div className={styles["box__right"]}>
              {rightQuestions.map((item) => (
                <QuestionItem
                  item={item}
                  key={item.key}
                  questionsSelected={questionsSelected}
                  setQuestionsSelected={setQuestionsSelected}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const QuestionItem = (props: {
  questionsSelected: string[];
  item: { key: string; answer: string; question: string };
  setQuestionsSelected: (questionsSelected: string[]) => void;
}) => {
  const { item, questionsSelected, setQuestionsSelected } = props;

  return (
    <div className={styles["box__item"]}>
      <div className={styles["item__question"]}>
        <span className={styles["question__text"]}>{item.question}</span>
        <span
          className={styles["question__expand"]}
          onClick={() => {
            const selectedIndex = questionsSelected.indexOf(item.key);

            if (selectedIndex === -1) {
              setQuestionsSelected([...questionsSelected, item.key]);
            } else {
              setQuestionsSelected(
                questionsSelected.filter((element) => item.key !== element)
              );
            }
          }}
        >
          {questionsSelected.includes(item.key) ? "-" : "+"}
        </span>
      </div>
      {questionsSelected.includes(item.key) && (
        <div
          className={styles["item__answer"]}
          dangerouslySetInnerHTML={{ __html: item.answer }}
        />
      )}
    </div>
  );
};

export default Question;
