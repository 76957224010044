import callApi from ".";

const getGifts = async () => {
  const { data } = await callApi({
    endpoint: "/web/fortune-wheel/prizes",
  });

  return data;
};

const spinWheel = async () => {
  const { data } = await callApi({
    endpoint: "/web/fortune-wheel/spin",
  });

  return data;
};

export { getGifts, spinWheel };
