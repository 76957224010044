// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../public/images/home-background.webp", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Layout_layout-wrapper__v73ww {
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.Layout_layout-wrapper__v73ww .Layout_layout-wrapper__gmail__3bAkU {
  top: 50vh;
  z-index: 5;
  right: 20px;
  width: 64px;
  height: 64px;
  display: flex;
  cursor: pointer;
  position: fixed;
  align-items: center;
  border-radius: 64px;
  background: #ffffff;
  flex-direction: column;
  justify-content: center;
  border: 5px solid #5481f3;
}
.Layout_layout-wrapper__v73ww .Layout_layout-wrapper__gmail__3bAkU img {
  width: 33px;
  height: 22px;
}
.Layout_layout-wrapper__v73ww .Layout_layout-wrapper__gmail__3bAkU span {
  font-size: 10px;
  margin-top: 3px;
  color: #333333;
  font-weight: 700;
}

@media screen and (max-width: 425px) {
  .Layout_layout-wrapper__v73ww {
    overflow: hidden;
    background-size: contain;
  }
  .Layout_layout-wrapper__v73ww .Layout_layout-wrapper__gmail__3bAkU {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/Layout/index.module.scss"],"names":[],"mappings":"AAAA;EACE,yDAAA;AACF;AACE;EACE,SAAA;EACA,UAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;EACA,eAAA;EACA,mBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;EACA,yBAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAEI;EACE,eAAA;EACA,eAAA;EACA,cAAA;EACA,gBAAA;AAAN;;AAKA;EACE;IACE,gBAAA;IACA,wBAAA;EAFF;EAIE;IACE,aAAA;EAFJ;AACF","sourcesContent":[".layout-wrapper {\n  background-image: url(\"../../../public/images/home-background.webp\");\n\n  .layout-wrapper__gmail {\n    top: 50vh;\n    z-index: 5;\n    right: 20px;\n    width: 64px;\n    height: 64px;\n    display: flex;\n    cursor: pointer;\n    position: fixed;\n    align-items: center;\n    border-radius: 64px;\n    background: #ffffff;\n    flex-direction: column;\n    justify-content: center;\n    border: 5px solid #5481f3;\n\n    img {\n      width: 33px;\n      height: 22px;\n    }\n\n    span {\n      font-size: 10px;\n      margin-top: 3px;\n      color: #333333;\n      font-weight: 700;\n    }\n  }\n}\n\n@media screen and (max-width: 425px) {\n  .layout-wrapper {\n    overflow: hidden;\n    background-size: contain;\n\n    .layout-wrapper__gmail {\n      display: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout-wrapper": `Layout_layout-wrapper__v73ww`,
	"layout-wrapper__gmail": `Layout_layout-wrapper__gmail__3bAkU`
};
export default ___CSS_LOADER_EXPORT___;
