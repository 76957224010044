import "swiper/css";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./index.module.scss";

type Props = {
  calendars: any[];
  setMonthSelected: (month: any) => void;
};

const CalendarSwiper = ({ calendars, setMonthSelected }: Props) => {
  const [activedIndex, setActivedIndex] = useState<number>(0);

  return (
    <Swiper loop={true} slidesPerView={3} style={{ height: "100%" }}>
      {calendars?.map((item) => (
        <SwiperSlide
          key={item.id}
          onClick={() => {
            setMonthSelected(item);
            setActivedIndex(item.id);
          }}
        >
          <div className={styles["calendar-swiper__wrapper"]}>
            <div className={styles["mobile__item"]}>
              <span
                className={`${styles["item__month"]} ${
                  activedIndex === item.id ? styles["item__month--hover"] : ""
                }`}
              >
                T{item.id}
              </span>
              <div className={styles["item__events"]}>
                {item.content
                  .slice(0, 3)
                  .map((element: string, index: number) => (
                    <span key={index} className={styles["event--text"]}>
                      {element}
                    </span>
                  ))}
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CalendarSwiper;
