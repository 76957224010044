import { toast } from "react-toastify";
import { useState, useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { AppContext } from "../../../contexts";
import { CHALLENGE } from "../../../constants";
import { shareDailyEmotion } from "../../../services/challenge";

const FeelingModal = () => {
  const { accountState, accountDispatch } = useContext(AppContext);

  const [feeling, setFeeling] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [emojiMoveOut, setEmojiMoveOut] = useState("");
  const [emojiMoveOver, setEmojiMoveOver] = useState("");
  const [emojiChecked, setEmojiChecked] = useState<CHALLENGE.EmotionType>();

  const handleClickShare = async () => {
    if (!emojiChecked) {
      toast.info("Đừng quên chọn emotion nhé", {
        autoClose: 5000,
        hideProgressBar: true,
        toastId: "chooseEmotion",
      });

      return;
    }

    try {
      setIsLoading(true);

      const { result } = await shareDailyEmotion({
        message: feeling,
        emotionType: emojiChecked,
      });

      if (result) {
        setIsLoading(false);
        accountDispatch({ payload: true, type: "U-SHARED_EMOTION" });
      }
    } catch (err: any) {
      toast.error(err.message, { toastId: "shareDailyEmotion" });
    }
  };

  useEffect(() => {
    if (accountState.id && !accountState.shared_emotion) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [accountState.id, accountState.shared_emotion]);

  useEffect(() => {
    document.body.style.overflow = `${isOpen ? "hidden" : "unset"}`;
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles["modal-wrapper"]}>
      <div className={styles["modal-wrapper__main"]}>
        <svg width="755" height="670" viewBox="0 0 860 766">
          <path
            fill="white"
            d="M0 40C0 17.9086 17.9086 0 40 0H720C742.091 0 760 17.9086 760 40V60C760 82.0914 777.909 100 800 100H820C842.091 100 860 117.909 860 140V726C860 748.091 842.091 766 820 766H140C117.909 766 100 748.091 100 726V706C100 683.909 82.0914 666 60 666H40C17.9086 666 0 648.091 0 626V40Z"
          />
        </svg>
        <div className={styles["main__content"]}>
          <span className={styles["content__title"]}>
            Inside Appota chào bạn
          </span>
          <span className={styles["content__title"]}>
            Tâm trạng của bạn thế nào nhỉ?
          </span>
          <div className={styles["content__emoji"]}>
            <img
              alt=""
              src="/images/emoji-background.svg"
              className={styles["emoji__background"]}
            />
            <div className={styles["emoji__group"]}>
              <div style={{ position: "relative" }}>
                <img
                  alt=""
                  src="/images/emoji.png"
                  onMouseOut={() => {
                    setEmojiMoveOver("");
                    setEmojiMoveOut("1");
                  }}
                  onMouseOver={() => setEmojiMoveOver("1")}
                  className={`${styles["emoji"]} ${styles["emoji-2-1"]} ${
                    emojiMoveOut === "1" ? styles["emoji-1-2"] : ""
                  } ${
                    emojiChecked === CHALLENGE.EmotionType.SAD
                      ? styles["emoji-checked"]
                      : ""
                  }`}
                  onClick={() => setEmojiChecked(CHALLENGE.EmotionType.SAD)}
                />
                {emojiMoveOver === "1" ||
                emojiChecked === CHALLENGE.EmotionType.SAD ? (
                  <div className={styles["emoji__description-wrapper"]}>
                    <div className={styles["emoji__description-1"]}>
                      <span>Buồn bã</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }}>
                <img
                  alt=""
                  src="/images/emoji-1.png"
                  onMouseOut={() => setEmojiMoveOver("")}
                  onMouseOver={() => setEmojiMoveOver("2")}
                  className={`${styles["emoji"]} ${
                    emojiChecked === CHALLENGE.EmotionType.HAPPY
                      ? styles["emoji-checked"]
                      : ""
                  }`}
                  onClick={() => setEmojiChecked(CHALLENGE.EmotionType.HAPPY)}
                />
                {emojiMoveOver === "2" ||
                emojiChecked === CHALLENGE.EmotionType.HAPPY ? (
                  <div className={styles["emoji__description-wrapper"]}>
                    <div className={styles["emoji__description-1"]}>
                      <span>Vui vẻ</span>
                    </div>
                  </div>
                ) : null}
              </div>
              <div style={{ position: "relative" }}>
                <img
                  alt=""
                  onMouseOut={() => {
                    setEmojiMoveOver("");
                    setEmojiMoveOut("3");
                  }}
                  src="/images/emoji-2.png"
                  onMouseOver={() => setEmojiMoveOver("3")}
                  className={`${styles["emoji"]} ${styles["emoji-2-3"]} ${
                    emojiMoveOut === "3" ? styles["emoji-3-2"] : ""
                  } ${
                    emojiChecked === CHALLENGE.EmotionType.NEUTRAL
                      ? styles["emoji-checked"]
                      : ""
                  }`}
                  onClick={() => setEmojiChecked(CHALLENGE.EmotionType.NEUTRAL)}
                />
                {emojiMoveOver === "3" ||
                emojiChecked === CHALLENGE.EmotionType.NEUTRAL ? (
                  <div className={styles["emoji__description-wrapper"]}>
                    <div className={styles["emoji__description-1"]}>
                      <span>Bình thường</span>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          <div className={styles["content__field"]}>
            <textarea
              maxLength={100}
              onChange={(e) => setFeeling(e.target.value)}
              placeholder="Chia sẻ cùng Appoter nhé buddy!!!"
            />
            <span>{feeling.length}/100</span>
          </div>
          <button
            disabled={isLoading}
            onClick={handleClickShare}
            className={styles["content__button"]}
          >
            Share liền
          </button>
        </div>
      </div>
    </div>
  );
};

export default FeelingModal;
