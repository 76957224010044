import PortalModal from "./Portal";
import ContactModal from "./Contact";
import FeelingModal from "./Feeling";
import EmployeeModal from "./Employee";
import EventUploadModal from "./EventUpload";

export {
  PortalModal,
  ContactModal,
  FeelingModal,
  EmployeeModal,
  EventUploadModal,
};
