import "swiper/css";
import { Swiper, SwiperSlide } from "swiper/react";

import styles from "./index.module.scss";

type Props = {
  setCardHover: (index: string) => void;
};

const CoreValuesSwiper = ({ setCardHover }: Props) => {
  return (
    <Swiper
      loop={true}
      onActiveIndexChange={(e: any) => setCardHover(e.realIndex.toString())}
    >
      {[
        {
          key: "0",
          alphabet: "I",
          value: "(Integrity - Chính trực)",
          icon: "/images/tho-than-1.png",
          description:
            "Tại Appota, chính trực được xem là giá trị cốt lõi hàng đầu của doanh nghiệp. Chúng ta cam kết thực hiện nhất quán trong suy nghĩ, lời nói và hành động với người khác. Chúng ta nỗ lực xây dựng một môi trường làm việc đáng tự hào nơi mà mọi người luôn đề cao sự trung thực và tin tưởng lẫn nhau.",
        },
        {
          key: "1",
          alphabet: "A",
          value: "(Adaptability -Thích nghi)",
          icon: "/images/tho-than-2.png",
          description:
            "Có một thứ không bao giờ thay đổi, đó chính là sự thay đổi. Đối với người Appota, thích nghi với mọi sự thay đổi, sẵn sàng đón nhận mọi thử thách là điều tất yếu. Để chúng ta không ai bị bỏ lại phía sau thì mỗi cá nhân cần không ngừng học hỏi, rèn luyện khả năng sáng tạo, linh hoạt hơn trong mọi hoàn cảnh.",
        },
        {
          key: "2",
          alphabet: "P",
          value: "(Proactivity - Chủ động)",
          icon: "/images/tho-than-3.png",
          description:
            "Chủ động là chìa khóa dẫn lối thành công, quyết định tương lai của mỗi người. Tại Appota, mỗi thành viên luôn được trao quyền để làm chủ trong chính công việc của mình. Chúng ta không chờ đợi cơ hội đến với mình mà chúng ta chủ động nắm bắt cơ hội và tự tạo ra cơ hội cho chính bản thân.",
        },
        {
          key: "3",
          alphabet: "P",
          value: "(Positivity - Tích cực)",
          icon: "/images/tho-than-4.png",
          description:
            "Appota luôn hướng tới một môi trường làm việc tích cực cho nhân sự. Đối mặt với những thử thách thay vì việc than vãn, đổ lỗi, buông xuôi thì chúng ta hãy suy nghĩ và hành động một cách lạc quan nhất. Học cách lan tỏa những giá trị tốt đẹp đến mọi người sẽ khiến cho bản thân tốt hơn mỗi ngày.",
        },
        {
          key: "4",
          alphabet: "O",
          value: "(Owership - Máu lửa)",
          icon: "/images/tho-than-5.png",
          description:
            "Máu lửa trong từng suy nghĩ và hành động vừa là bệ phóng vững chắc để mọi nhân sự khai phá năng lực bản thân, vừa là kim chỉ nam giúp cho doanh nghiệp phát triển một cách toàn diện, bền vững. Mỗi thành viên của Appota đều được khuyến khích làm mọi thứ dựa trên tinh thần sở hữu, máu lửa và chủ động.",
        },
        {
          key: "5",
          alphabet: "T",
          value: "(Teamwork - Đồng bộ)",
          icon: "/images/tho-than-6.png",
          description:
            "Sức mạnh đồng bộ thể hiện ở một tập thể cam kết hoạt động vì một mục tiêu chung. Mỗi cá nhân, mỗi phòng ban tại Appota luôn sẵn sàng hợp tác, kết nối, hỗ trợ lẫn nhau nhằm tìm kiếm giải pháp tối ưu và chinh phục mọi thử thách.",
        },
        {
          key: "6",
          alphabet: "A",
          value: "(Authenticity - Hãy là chính mình)",
          icon: "/images/tho-than-7.png",
          description:
            " Appota khuyến khích nhân viên tự tin thể hiện cá tính riêng, tự do làm điều mình thích một cách đúng đắn và thẳng thắn phản hồi mọi vấn đề mà không phân biệt cấp bậc. Không chỉ là chính mình mà chúng ta luôn nỗ lực không ngừng để trở thành phiên bản tốt nhất của chính mình.",
        },
      ].map((item) => (
        <SwiperSlide key={item.key}>
          <div className={styles["mobile__card"]}>
            <span className={styles["card__alphabet"]}>{item.alphabet}</span>
            <span className={styles["card__value"]}>{item.value}</span>
            <img alt="" src={item.icon} className={styles["card__image"]} />
            <div className={styles["card__description"]}>
              {item.description}
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CoreValuesSwiper;
