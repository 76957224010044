import { createContext } from "react";

import { initialUIState } from "./ui";
import { initialAccountState } from "./account";
import { initialNotificationState } from "./notification";

export type TAction = {
  type: string;
  payload: any;
};

const AppContext = createContext({
  uiState: initialUIState,
  uiDispatch: (action: TAction) => {},

  accountState: initialAccountState,
  accountDispatch: (action: TAction) => {},

  notificationState: initialNotificationState,
  notificationDispatch: (action: TAction) => {},

  isGlobalLoading: false,
  setIsGlobalLoading: (isLoading: boolean) => {},
});

export { AppContext };
