import moment from "moment";
import { flatMap } from "lodash";
import { useEffect } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import InfiniteScroll from "react-infinite-scroll-component";

import styles from "./index.module.scss";
import { getNotifications } from "../../services/notification";
import NotificationItem, { Notification } from "./NotificationItem";

type Props = {
  isRead?: boolean;
  onRefresher: number;
  refresher: Function;
  openPointPopup: Function;
};

interface INotificationPage {
  page: number;
  hasNextPage: boolean;
  items: Array<Notification>;
}

const NotificationList = ({
  isRead,
  refresher,
  onRefresher,
  openPointPopup,
}: Props) => {
  let queryKey = "all";
  if (isRead !== undefined) {
    queryKey = isRead ? "read" : "unread";
  }

  const {
    refetch,
    isFetching,
    hasNextPage,
    fetchNextPage,
    data: notifications,
  } = useInfiniteQuery({
    initialPageParam: 1,
    queryKey: [`notification-${queryKey}`],
    queryFn: ({ pageParam }: { pageParam: number }) =>
      getNotifications({ isRead, page: pageParam }),
    getNextPageParam: (lastPage: any) =>
      lastPage.hasNextPage ? lastPage.page + 1 : undefined,
  });

  const filterRecentNotification = (
    notiList: INotificationPage[],
    isRecent = true
  ) => {
    const today = moment();
    const items = flatMap(notiList, (el) => el.items);

    return items.filter((noti) =>
      isRecent
        ? moment(noti.createdAt).diff(today, "days") === 0
        : moment(noti.createdAt).diff(today, "days") !== 0
    );
  };

  useEffect(() => {
    refetch();
  }, [onRefresher, refetch]);

  if (!notifications) return <></>;

  return (
    <InfiniteScroll
      loader={<></>}
      hasMore={hasNextPage}
      next={() => fetchNextPage()}
      scrollableTarget={`scrollable-${queryKey}}`}
      dataLength={notifications.pages ? notifications.pages.length : 0}
    >
      <div id={`scrollable-${queryKey}}`} className={styles["content__tab"]}>
        {!!filterRecentNotification(notifications.pages).length && (
          <div className={styles["tab--today"]}>
            <span className={styles["tab__title"]}>Hôm nay</span>
            {filterRecentNotification(notifications?.pages).map(
              (notification: Notification) => (
                <NotificationItem
                  key={notification.id}
                  openPoint={openPointPopup}
                  notification={notification}
                  refetchNotifications={refresher}
                />
              )
            )}
          </div>
        )}
        {!!filterRecentNotification(notifications.pages, false).length && (
          <div className={styles["tab--previous-days"]}>
            <span className={styles["tab__title"]}>Trước đó</span>
            {filterRecentNotification(notifications.pages, false).map(
              (notification: Notification) => (
                <NotificationItem
                  key={notification.id}
                  openPoint={openPointPopup}
                  notification={notification}
                  refetchNotifications={refresher}
                />
              )
            )}
          </div>
        )}
        {isFetching && <p className={styles["tab__loader"]}>Đang tải ...</p>}
      </div>
    </InfiniteScroll>
  );
};

export default NotificationList;
