import "swiper/css";
import moment from "moment";
import parse from "html-react-parser";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useRef, useState, useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { AppContext } from "../../contexts";
import { useScreenDetector } from "../../hooks";
import { getEvents } from "../../services/event";
import { getLeaderboard } from "../../services/challenge";
import { EventUploadModal } from "../../components/Modals";
import { Layout, SpinWheel, Congratulation } from "../../components";

const Event = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const searchRef = useRef<HTMLInputElement>(null);
  const { setIsGlobalLoading } = useContext(AppContext);

  const [gift, setGift] = useState<string>("");
  const [selectedEvent, setSelectedEvent] = useState<any>();
  const [isOpenSpinWheel, setIsOpenSpinWheel] = useState<boolean>(false);
  const [isShowCloseIcon, setIsShowCloseIcon] = useState<boolean>(false);
  const [isOpenUploadModal, setIsOpenUploadModal] = useState<boolean>(false);

  const { isMobile } = useScreenDetector();

  const {
    refetch,
    data: eventList,
    isPending: isEventPending,
  } = useQuery({
    queryKey: ["events"],
    queryFn: () => getEvents({ name: searchRef.current?.value }),
  });

  useEffect(() => {
    if (eventList?.data && eventList.data.length) {
      setSelectedEvent(eventList.data[0]);
    }
  }, [eventList]);

  useEffect(() => {
    const eventId = searchParams.get("id");

    if (eventId && eventList.data.length) {
      const result = eventList.data.find((item: any) => item.id === eventId);

      if (result) setSelectedEvent(result);

      document.documentElement.scrollTo({ top: 192 });
    }
  }, [eventList, searchParams]);

  useEffect(() => {
    setIsGlobalLoading(isEventPending);
  }, [isEventPending, setIsGlobalLoading]);

  return (
    <Layout>
      <div className={styles["event-wrapper"]}>
        <div className={styles["event-wrapper__content"]}>
          <span className={styles["content__title"]}>Events</span>
          <div className={styles["content__box"]}>
            <div className={styles["box__left"]}>
              <div className={styles["left__search"]}>
                <img
                  alt=""
                  src="/images/magnifying-glass-icon.svg"
                  className={styles["search__magnifying-icon"]}
                />
                <input
                  ref={searchRef}
                  placeholder="Tìm kiếm sự kiện"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") refetch();
                  }}
                  onChange={(e) => setIsShowCloseIcon(Boolean(e.target.value))}
                />
                {isShowCloseIcon ? (
                  <img
                    alt=""
                    src="/images/close-icon.svg"
                    onClick={() => {
                      setIsShowCloseIcon(false);
                      searchRef.current!.value = "";

                      refetch();
                    }}
                    className={styles["search__close-icon"]}
                  />
                ) : null}
              </div>
              <div className={styles["left__content"]}>
                {isMobile ? (
                  /* Mobile */
                  <Swiper slidesPerView={1}>
                    {eventList?.data.map((item: any) => (
                      <SwiperSlide key={item.id}>
                        <div
                          onClick={() => setSelectedEvent(item)}
                          className={`${styles["content__item"]} ${
                            item.id === selectedEvent?.id
                              ? styles["content__item--active"]
                              : ""
                          }`}
                        >
                          <div className={styles["item__thumbnail"]}>
                            <img
                              alt=""
                              src={`${process.env.REACT_APP_FILE_URL}/${item.thumbnail}`}
                            />
                          </div>
                          <div className={styles["item__info"]}>
                            <span className={styles["item__name"]}>
                              {item.title}
                            </span>
                            <span className={styles["item__date"]}>
                              {moment(item.start_date).format("DD/MM/YYYY")} -
                              {moment(item.end_date).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                ) : (
                  /* Mobile */
                  /* PC */
                  eventList?.data.map((item: any) => (
                    <div
                      key={item.id}
                      onClick={() => setSelectedEvent(item)}
                      className={`${styles["content__item"]} ${
                        item.id === selectedEvent?.id
                          ? styles["content__item--active"]
                          : ""
                      }`}
                    >
                      <div className={styles["item__thumbnail"]}>
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_FILE_URL}/${item.thumbnail}`}
                        />
                      </div>
                      <div className={styles["item__info"]}>
                        <span className={styles["item__name"]}>
                          {item.title}
                        </span>
                        <span className={styles["item__date"]}>
                          {moment(item.start_date).format("DD/MM/YYYY")} -
                          {moment(item.end_date).format("DD/MM/YYYY")}
                        </span>
                      </div>
                    </div>
                  ))
                  /* PC */
                )}
              </div>
            </div>
            {selectedEvent ? (
              <div className={styles["box__right"]}>
                <div className={styles["right__event-name"]}>
                  {selectedEvent.title}
                </div>
                <div className={styles["right__buttons"]}>
                  {selectedEvent.is_joined ||
                  !moment().isBetween(
                    selectedEvent.start_date,
                    selectedEvent.end_date
                  ) ? null : (
                    <div
                      className={styles["button__join"]}
                      onClick={() => setIsOpenUploadModal(true)}
                    >
                      <svg width="24" height="24">
                        <path
                          fill="#0E2FDB"
                          d="M7.80562 14.4974L5.7173 13.5L3.95418 17.8539C3.94869 17.8676 3.94338 17.8815 3.9388 17.8954C3.71486 18.5673 3.76466 19.1777 4.07924 19.6139C4.35188 19.9924 4.79024 20.2009 5.31355 20.2009H5.31374C5.56148 20.2009 5.82771 20.1541 6.10494 20.0616C6.11903 20.057 6.13295 20.0519 6.1465 20.0464L10.5004 18.2833L9.50319 16.1948C9.23641 15.6365 8.36391 14.764 7.80562 14.4974Z"
                        />
                        <path
                          fill="#0E2FDB"
                          d="M23.9399 1.05469L10.2305 14.7642C10.4581 15.0445 10.6476 15.3283 10.7721 15.5887L14.252 22.8755C14.7195 23.8544 15.3358 24.0005 15.6614 24.0005H15.6616C15.9525 24.0005 16.6712 23.874 17.0621 22.7014L23.8607 2.30585C24.013 1.84845 24.0383 1.41962 23.9399 1.05469Z"
                        />
                        <path
                          fill="#0E2FDB"
                          d="M9.23618 13.7693L22.9464 0.0591431C22.8026 0.0201416 22.6485 0 22.4861 0C22.2379 0 21.9717 0.046875 21.6945 0.139343L1.29911 6.93768C0.220065 7.29749 0.0234092 7.92554 0.00216899 8.26776C-0.0190712 8.61017 0.0986656 9.25763 1.12497 9.74762L8.41184 13.2277C8.67203 13.3522 8.95584 13.5417 9.23618 13.7693Z"
                        />
                      </svg>
                      <span>Tham gia sự kiện</span>
                    </div>
                  )}
                  <div
                    className={styles["button__view"]}
                    onClick={() => navigate(`/event/${selectedEvent.id}`)}
                  >
                    <svg width="25" height="24">
                      <path
                        fill="#222222"
                        d="M8.30562 14.4974L6.2173 13.5L4.45418 17.8539C4.44869 17.8676 4.44338 17.8815 4.4388 17.8954C4.21486 18.5673 4.26466 19.1777 4.57924 19.6139C4.85188 19.9924 5.29024 20.2009 5.81355 20.2009H5.81374C6.06148 20.2009 6.32771 20.1541 6.60494 20.0616C6.61903 20.057 6.63295 20.0519 6.6465 20.0464L11.0004 18.2833L10.0032 16.1948C9.73641 15.6365 8.86391 14.764 8.30562 14.4974Z"
                      />
                      <path
                        fill="#222222"
                        d="M24.4399 1.05469L10.7305 14.7642C10.9581 15.0445 11.1476 15.3283 11.2721 15.5887L14.752 22.8755C15.2195 23.8544 15.8358 24.0005 16.1614 24.0005H16.1616C16.4525 24.0005 17.1712 23.874 17.5621 22.7014L24.3607 2.30585C24.513 1.84845 24.5383 1.41962 24.4399 1.05469Z"
                      />
                      <path
                        fill="#222222"
                        d="M9.73618 13.7693L23.4464 0.0591431C23.3026 0.0201416 23.1485 0 22.9861 0C22.7379 0 22.4717 0.046875 22.1945 0.139343L1.79911 6.93768C0.720065 7.29749 0.523409 7.92554 0.502169 8.26776C0.480929 8.61017 0.598666 9.25763 1.62497 9.74762L8.91184 13.2277C9.17203 13.3522 9.45584 13.5417 9.73618 13.7693Z"
                      />
                    </svg>
                    <span>Xem sự kiện</span>
                  </div>
                </div>
                <div className={styles["right__thumbnail"]}>
                  <img
                    alt=""
                    src={`${process.env.REACT_APP_FILE_URL}/${selectedEvent.thumbnail}`}
                  />
                </div>
                <div className={styles["right__description"]}>
                  {parse(selectedEvent?.content || "")}
                </div>
              </div>
            ) : null}
          </div>
          <div className={styles["content__mission"]}>
            <span className={styles["mission__title"]}>Cơ cấu tính điểm</span>
            <div className={styles["mission__group"]}>
              {[
                {
                  name: "Chia sẻ cảm xúc mỗi ngày",
                  description: "+200 điểm/lần share đầu tiên",
                },
                {
                  name: "Đọc tin",
                  description: "50 điểm/lần xem đầu tiên/tin",
                },
                {
                  name: "Thả cảm xúc",
                  description: "50 điểm/lần thả cảm xúc",
                },
                {
                  name: "Bình luận",
                  description: "50 điểm/bình luận hợp lệ",
                },
                {
                  name: "Tham gia challenge",
                  description: "300 điểm/challenge",
                },
                {
                  name: "Chiến thắng challenge",
                  description: "500 điểm/challenge",
                },
                {
                  name: "Top đi làm sớm",
                  description: "10 điểm/ngày",
                },
                {
                  name: "Tham gia Fest Event",
                  description: "500 điểm/sự kiện",
                },
                {
                  name: "Tham gia Core Event",
                  description: "1000 điểm/sự kiện",
                },
                {
                  name: "Hoàn thành survey sự kiện",
                  description: "100 điểm/survey",
                },
                {
                  name: "Lan tỏa sự kiện",
                  description: "200 điểm/sự kiện",
                },
                {
                  name: "Đóng góp sự kiện",
                  description: "300 điểm/sự kiện",
                },
              ].map((item, index) => (
                <div key={index} className={styles["mission__item"]}>
                  <img
                    alt=""
                    className={styles["image__corner"]}
                    src="/images/news-mask-right-corner.svg"
                  />
                  <div className={styles["item__content"]}>
                    <span>{item.name}</span>
                    <span className={styles["content__description"]}>
                      {item.description}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* Rankings */}
        <Rankings setIsOpenSpinWheel={setIsOpenSpinWheel} />
        {/* Rankings */}
      </div>
      <EventUploadModal
        event={selectedEvent}
        isOpen={isOpenUploadModal}
        onClose={() => setIsOpenUploadModal(false)}
      />
      <Congratulation
        gift={gift}
        isOpen={Boolean(gift)}
        onClose={() => setGift("")}
      />
      <SpinWheel
        setGift={setGift}
        isOpen={isOpenSpinWheel}
        onClose={() => setIsOpenSpinWheel(false)}
      />
    </Layout>
  );
};

const Rankings = ({
  setIsOpenSpinWheel,
}: {
  setIsOpenSpinWheel: (isOpenSpinWheel: boolean) => void;
}) => {
  const { data: leaderboard } = useQuery({
    queryKey: ["leaderboard"],
    queryFn: () =>
      getLeaderboard({
        page: 1,
        pageSize: 10,
        // to: moment().endOf("month").format("YYYY-MM-DD"),
        // from: moment().startOf("month").format("YYYY-MM-DD"),
      }),
  });

  return (
    <div className={styles["event-wrapper__rankings"]}>
      <div className={styles["rankings__title"]}>
        <div>
          <img
            alt=""
            src="/images/leaves-left.svg"
            className={styles["title__icon-1"]}
          />
          <span>Top 10 Thổ Tinh Tú</span>
          <img
            alt=""
            src="/images/leaves-right.svg"
            className={styles["title__icon-2"]}
          />
        </div>
        <div className={styles["subtitle"]}>
          <span>
            Top 10 có số điểm cao nhất trong tháng & có cơ hội đổi điểm lấy quà
          </span>
        </div>
      </div>
      <div className={styles["rankings__box"]}>
        <div className={styles["box__image"]}>
          <img alt="" src="/images/tho-than-8.png" />
          <div
            className={styles["box__button"]}
            onClick={() => setIsOpenSpinWheel(true)}
          >
            <span>Đổi điểm lấy quà</span>
          </div>
        </div>
        <div className={styles["box__content"]}>
          <div className={styles["box__content-2"]}>
            {leaderboard?.data.map((item: any, index: number) => (
              <div key={index} className={styles["content-2__item"]}>
                <div className={styles["item__avatar"]}>
                  <img
                    alt=""
                    src={item.employee_avatar}
                    className={styles["avatar__image"]}
                  />
                  {parseInt(item.index) === 1 ? (
                    <img
                      alt=""
                      src="/images/crown-icon.svg"
                      className={styles["avatar__icon"]}
                    />
                  ) : null}
                </div>
                <div className={styles["item__info"]}>
                  <span
                    className={`${styles["info__name"]} ${
                      parseInt(item.index) < 4 ? styles["info__name-top-3"] : ""
                    }`}
                  >
                    {item.employee_name}
                  </span>
                  <div className={styles["info__position"]}>
                    <div className={styles["position__department"]} />
                    <span>{item.company_name}</span>
                  </div>
                </div>
                <span className={styles["item__point"]}>
                  {item.total_points}pts
                </span>
                <span
                  className={`${styles["item__rank"]} ${
                    parseInt(item.index) < 4 ? styles["item__rank-top-3"] : ""
                  }`}
                >
                  #{item.index}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Event;
