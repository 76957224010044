import "swiper/css";
import moment from "moment";
import parse from "html-react-parser";
import { Autoplay } from "swiper/modules";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { useState, useEffect, useContext } from "react";

import styles from "./index.module.scss";
import { AppContext } from "../../../contexts";
import { getLifes } from "../../../services/life";
import { useScreenDetector } from "../../../hooks";
import { getEvents } from "../../../services/event";
import { getMissions } from "../../../services/mission";
import { Layout, CoreValueSwiper } from "../../../components";

const Home2 = () => {
  const navigate = useNavigate();
  const { setIsGlobalLoading } = useContext(AppContext);

  const [cardHover, setCardHover] = useState<string>("");
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);
  const [firstSpotlightEvent, setFirstSpotlightEvent] = useState<any>(null);

  const { isTablet, isMobile } = useScreenDetector();

  const { data: lifeList, isPending: isLifeListPending } = useQuery({
    queryKey: ["lifes"],
    queryFn: () => getLifes(),
  });

  const { data: missionList, isPending: isMissionListPending } = useQuery({
    queryKey: ["missions"],
    queryFn: () => getMissions(),
  });

  const { data: recentEvents, isPending: isRecentEventsPending } = useQuery({
    queryKey: ["events-recent"],
    queryFn: () => getEvents({}),
  });

  const { data: spotlightEvent, isPending: isSpotlightEventPending } = useQuery(
    {
      queryKey: ["events"],
      queryFn: () => getEvents({ is_spotlight: true }),
    }
  );

  useEffect(() => {
    if (
      spotlightEvent &&
      spotlightEvent.data &&
      spotlightEvent.data.length > 0
    ) {
      setFirstSpotlightEvent(spotlightEvent.data[0]);
    }
  }, [spotlightEvent]);

  useEffect(() => {
    setIsGlobalLoading(
      isLifeListPending ||
        isMissionListPending ||
        isRecentEventsPending ||
        isSpotlightEventPending
    );
  }, [
    isLifeListPending,
    setIsGlobalLoading,
    isMissionListPending,
    isRecentEventsPending,
    isSpotlightEventPending,
  ]);

  return (
    <Layout>
      <div className={styles["home-v2-wrapper"]}>
        {/* Spotlight */}
        <div className={styles["home-v2-wrapper__hero"]}>
          <div className={styles["hero__content"]}>
            <div className={styles["content__header"]}>
              <span className={styles["header--highlight"]}>
                Sự kiện sắp tới
              </span>
              <span className={styles["header--time"]}>
                {moment(firstSpotlightEvent?.start_date).format("DD")} tháng{" "}
                {moment(firstSpotlightEvent?.start_date).format("MM")},{" "}
                {moment(firstSpotlightEvent?.start_date).format("YYYY")}
              </span>
            </div>
            <div className={styles["content_title"]}>
              <span>{firstSpotlightEvent?.title}</span>
            </div>
            <div className={styles["content_description"]}>
              <span className={styles["content_text"]}>
                {parse(firstSpotlightEvent?.short_description || "")}
              </span>
              <div
                className={styles["description__button"]}
                onClick={() => navigate(`/event?id=${firstSpotlightEvent?.id}`)}
              >
                <span>Xem chi tiết</span>
              </div>
            </div>
            <div className={styles["content_recent-event"]}>
              <div className={styles["recent-event__title"]}>
                <img alt="" src="/images/calendar-icon.svg" />
                <span>Sự kiện vừa qua</span>
              </div>
              <div className={styles["recent-event__list"]}>
                <Swiper
                  modules={[Autoplay]}
                  autoplay={{ delay: 2500 }}
                  slidesPerView={isMobile ? 1 : 2}
                >
                  {recentEvents?.data.slice(0, 5).map((item: any) => (
                    <SwiperSlide>
                      <div
                        key={item.id}
                        className={styles["recent-event__item"]}
                        onClick={() => navigate(`/event?id=${item?.id}`)}
                      >
                        <img
                          alt=""
                          src={`${process.env.REACT_APP_FILE_URL}/${item.thumbnail}`}
                        />
                        <div className={styles["item__info"]}>
                          <span className={styles["info__title"]}>
                            {item.title}
                          </span>
                          <span className={styles["info__date"]}>
                            {moment(item?.start_date).format("DD")} tháng{" "}
                            {moment(item?.start_date).format("MM")},{" "}
                            {moment(item?.start_date).format("YYYY")}
                          </span>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </div>
            <img
              alt=""
              src="/images/mars.gif"
              className={styles["content__image--1"]}
            />
            <img
              alt=""
              src="/images/mercury.gif"
              className={styles["content__image--2"]}
            />
          </div>
          <div className={styles["hero__image"]}>
            <div className={styles["hero__image--main"]}>
              <svg viewBox="0 0 766 700">
                <mask fill="white" id="path-1-inside-1_687_6384">
                  <path d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z" />
                </mask>
                <path
                  fill="url(#pattern0_687_6384)"
                  d="M0 32C0 14.3269 14.3269 0 32 0H634C651.673 0 666 14.3269 666 32V68C666 85.6731 680.327 100 698 100H734C751.673 100 766 114.327 766 132V668C766 685.673 751.673 700 734 700L132 700C114.327 700 100 685.673 100 668V374C100 356.327 85.6731 342 68 342H32C14.3269 342 0 327.673 0 310V32Z"
                />
                <path
                  fill="white"
                  fillOpacity="0.5"
                  mask="url(#path-1-inside-1_687_6384)"
                  d="M132 700L132 696L132 700ZM734 700L734 704L734 700ZM634 -4H32V4H634V-4ZM670 68V32H662V68H670ZM734 96H698V104H734V96ZM770 668V132H762V668H770ZM132 704L734 704L734 696L132 696L132 704ZM96 374V668H104V374H96ZM32 346H68V338H32V346ZM-4 32V310H4V32H-4ZM32 338C16.536 338 4 325.464 4 310H-4C-4 329.882 12.1177 346 32 346V338ZM104 374C104 354.118 87.8823 338 68 338V346C83.464 346 96 358.536 96 374H104ZM132 696C116.536 696 104 683.464 104 668H96C96 687.882 112.118 704 132 704L132 696ZM762 668C762 683.464 749.464 696 734 696L734 704C753.882 704 770 687.882 770 668H762ZM734 104C749.464 104 762 116.536 762 132H770C770 112.118 753.882 96 734 96V104ZM662 68C662 87.8823 678.118 104 698 104V96C682.536 96 670 83.464 670 68H662ZM32 -4C12.1177 -4 -4 12.1178 -4 32H4C4 16.536 16.536 4 32 4V-4ZM634 4C649.464 4 662 16.536 662 32H670C670 12.1177 653.882 -4 634 -4V4Z"
                />
                <defs>
                  <pattern
                    width="1"
                    height="1"
                    id="pattern0_687_6384"
                    patternContentUnits="objectBoundingBox"
                  >
                    <use
                      xlinkHref="#image0_687_6384"
                      transform="matrix(0.000669478 0 0 0.000732601 -0.185546 0)"
                    />
                  </pattern>
                  <image
                    width="2048"
                    height="1365"
                    id="image0_687_6384"
                    xlinkHref={`${process.env.REACT_APP_FILE_URL}/${firstSpotlightEvent?.thumbnail}`}
                  />
                </defs>
              </svg>
              <img
                alt=""
                src="/images/hero-bottom-leftcorner-mask.svg"
                className={styles["hero__image--bottom-left-corner"]}
              />
            </div>
            <img
              alt=""
              src="/images/earth.gif"
              className={styles["hero__image--top-right-corner"]}
            />
          </div>
        </div>
        {/* Spotlight */}
        {/* Life at Appota */}
        <div className={styles["home-v2-wrapper__life"]}>
          <div className={styles["life__title"]}>
            <span>Life at Appota</span>
          </div>
          <div className={styles["life__content"]}>
            <Swiper slidesPerView={isMobile ? 1 : isTablet ? 2 : 4}>
              {lifeList?.map((item: any) => (
                <SwiperSlide key={item.id}>
                  <div className={styles["content__tag"]}>
                    <div>
                      <div className={styles["tag__name"]}>
                        <span>{item.name}</span>
                      </div>
                      <div className={styles["tag__list"]}>
                        {item.events.map((element: any) => (
                          <div key={element.id} className={styles["tag__item"]}>
                            <div className={styles["item__title"]}>
                              <div className={styles["title__info"]}>
                                <img
                                  alt=""
                                  src={`${process.env.REACT_APP_FILE_URL}/${element.icon}`}
                                />
                                <span>{element.title}</span>
                              </div>
                              <span
                                className={styles["item__expand"]}
                                onClick={() => {
                                  const selectedIndex = itemsSelected.indexOf(
                                    element.id
                                  );

                                  if (selectedIndex === -1) {
                                    setItemsSelected([
                                      ...itemsSelected,
                                      element.id,
                                    ]);
                                  } else {
                                    setItemsSelected(
                                      itemsSelected.filter(
                                        (x) => x !== element.id
                                      )
                                    );
                                  }
                                }}
                              >
                                {itemsSelected.includes(element.id) ? "-" : "+"}
                              </span>
                            </div>
                            {itemsSelected.includes(element.id) && (
                              <div
                                className={styles["item__description"]}
                                dangerouslySetInnerHTML={{
                                  __html: element.content,
                                }}
                              />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className={styles["tag__footer"]}>
                      <img
                        alt=""
                        src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                      />
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
        {/* Life at Appota */}
        {/* We are Appota */}
        <div className={styles["home-v2-wrapper__waa"]}>
          <div className={styles["waa__title"]}>
            <span>We are Appota</span>
          </div>
          <div className={styles["waa__content"]}>
            <div className={styles["waa__box"]}>
              {/* Mission */}
              <div className={styles["box__mission"]}>
                <div className={styles["box__title"]}>
                  <span>Sứ mệnh</span>
                </div>
                <div className={styles["mission__list"]}>
                  <Swiper slidesPerView={isMobile ? 1 : isTablet ? 2 : 3}>
                    {missionList?.data.map((item: any) => (
                      <SwiperSlide key={item.id}>
                        <div className={styles["mission__item"]}>
                          <img
                            alt=""
                            src={`${process.env.REACT_APP_FILE_URL}/${item.image}`}
                            className={styles["item__thumbnail"]}
                          />
                          <div className={styles["item__title"]}>
                            <span>{item.title}</span>
                          </div>
                          <div className={styles["item__description"]}>
                            <span>{item.content}</span>
                          </div>
                        </div>
                      </SwiperSlide>
                    ))}
                  </Swiper>
                </div>
              </div>
              {/* Mission */}
              {/* Core values */}
              <div className={styles["box__core-values"]}>
                <div className={styles["box__title"]}>
                  <span>Giá trị cốt lõi của Appota</span>
                </div>
                {/* PC */}
                <div className={styles["core-values__list"]}>
                  <div
                    className={`${styles["list__row"]} ${styles["list__row--1"]}`}
                  >
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("0")}
                      className={`${styles["row__card"]} ${styles["row--1__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>I</span>
                      <span className={styles["card__value"]}>
                        (Integrity - Chính trực)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-1.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Tại Appota, chính trực được xem là giá trị cốt lõi hàng
                        đầu của doanh nghiệp. Chúng ta cam kết thực hiện nhất
                        quán trong suy nghĩ, lời nói và hành động với người
                        khác. Chúng ta nỗ lực xây dựng một môi trường làm việc
                        đáng tự hào nơi mà mọi người luôn đề cao sự trung thực
                        và tin tưởng lẫn nhau.
                      </div>
                    </div>
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("6")}
                      className={`${styles["row__card"]} ${styles["row--1__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>A</span>
                      <span className={styles["card__value"]}>
                        (Authenticity - Hãy là chính mình)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-7.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Appota khuyến khích nhân viên tự tin thể hiện cá tính
                        riêng, tự do làm điều mình thích một cách đúng đắn và
                        thẳng thắn phản hồi mọi vấn đề mà không phân biệt cấp
                        bậc. Không chỉ là chính mình mà chúng ta luôn nỗ lực
                        không ngừng để trở thành phiên bản tốt nhất của chính
                        mình.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles["list__row"]} ${styles["list__row--2"]}`}
                  >
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("1")}
                      className={`${styles["row__card"]} ${styles["row--2__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>A</span>
                      <span className={styles["card__value"]}>
                        (Adaptability -Thích nghi)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-2.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Có một thứ không bao giờ thay đổi, đó chính là sự thay
                        đổi. Đối với người Appota, thích nghi với mọi sự thay
                        đổi, sẵn sàng đón nhận mọi thử thách là điều tất yếu. Để
                        chúng ta không ai bị bỏ lại phía sau thì mỗi cá nhân cần
                        không ngừng học hỏi, rèn luyện khả năng sáng tạo, linh
                        hoạt hơn trong mọi hoàn cảnh.
                      </div>
                    </div>
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("5")}
                      className={`${styles["row__card"]} ${styles["row--2__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>T</span>
                      <span className={styles["card__value"]}>
                        (Teamwork - Đồng bộ)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-6.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Sức mạnh đồng bộ thể hiện ở một tập thể cam kết hoạt
                        động vì một mục tiêu chung. Mỗi cá nhân, mỗi phòng ban
                        tại Appota luôn sẵn sàng hợp tác, kết nối, hỗ trợ lẫn
                        nhau nhằm tìm kiếm giải pháp tối ưu và chinh phục mọi
                        thử thách.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles["list__row"]} ${styles["list__row--3"]}`}
                  >
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("2")}
                      className={`${styles["row__card"]} ${styles["row--3__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>P</span>
                      <span className={styles["card__value"]}>
                        (Proactivity - Chủ động)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-3.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Chủ động là chìa khóa dẫn lối thành công, quyết định
                        tương lai của mỗi người. Tại Appota, mỗi thành viên luôn
                        được trao quyền để làm chủ trong chính công việc của
                        mình. Chúng ta không chờ đợi cơ hội đến với mình mà
                        chúng ta chủ động nắm bắt cơ hội và tự tạo ra cơ hội cho
                        chính bản thân.
                      </div>
                    </div>
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("4")}
                      className={`${styles["row__card"]} ${styles["row--3__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>O</span>
                      <span className={styles["card__value"]}>
                        (Owership - Máu lửa)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-5.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Máu lửa trong từng suy nghĩ và hành động vừa là bệ phóng
                        vững chắc để mọi nhân sự khai phá năng lực bản thân, vừa
                        là kim chỉ nam giúp cho doanh nghiệp phát triển một cách
                        toàn diện, bền vững. Mỗi thành viên của Appota đều được
                        khuyến khích làm mọi thứ dựa trên tinh thần sở hữu, máu
                        lửa và chủ động.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${styles["list__row"]} ${styles["list__row--4"]}`}
                  >
                    <div
                      onMouseOut={() => setCardHover("")}
                      onMouseOver={() => setCardHover("3")}
                      className={`${styles["row__card"]} ${styles["row--4__card"]}`}
                    >
                      <span className={styles["card__alphabet"]}>P</span>
                      <span className={styles["card__value"]}>
                        (Positivity - Tích cực)
                      </span>
                      <img
                        alt=""
                        src="/images/tho-than-4.png"
                        className={styles["card__image"]}
                      />
                      <div className={styles["card__description"]}>
                        Appota luôn hướng tới một môi trường làm việc tích cực
                        cho nhân sự. Đối mặt với những thử thách thay vì việc
                        than vãn, đổ lỗi, buông xuôi thì chúng ta hãy suy nghĩ
                        và hành động một cách lạc quan nhất. Học cách lan tỏa
                        những giá trị tốt đẹp đến mọi người sẽ khiến cho bản
                        thân tốt hơn mỗi ngày.
                      </div>
                    </div>
                  </div>
                </div>
                {/* PC */}
                {/* Mobile */}
                <div className={styles["core-values__list-mobile"]}>
                  <CoreValueSwiper setCardHover={setCardHover} />
                </div>
                {/* Mobile */}
              </div>
              <div className={styles["core-values__text"]}>
                {[
                  { key: "0", text: "I" },
                  { key: "1", text: "A" },
                  { key: "2", text: "P" },
                  { key: "3", text: "P" },
                  { key: "4", text: "O" },
                  { key: "5", text: "T" },
                  { key: "6", text: "A" },
                ].map((item) => (
                  <span
                    key={item.key}
                    className={`${
                      cardHover === item.key ? styles["text__active"] : ""
                    }`}
                  >
                    {item.text}
                  </span>
                ))}
              </div>
              {/* Core values */}
            </div>
          </div>
        </div>
        {/* We are Appota */}
      </div>
    </Layout>
  );
};

export default Home2;
