import callApi from ".";

const getEvent = async (id: string) => {
  const { data } = await callApi({ endpoint: `/web/a-event/${id}` });

  return data;
};

const getEvents = async (conditions: {
  name?: string;
  page?: number;
  orderBy?: string;
  is_spotlight?: boolean;
  sortOrder?: "ASC" | "DESC";
}) => {
  const { name, ...rest } = conditions;

  const { data } = await callApi({
    endpoint: "/web/a-event",
    query: { ...rest, term: name, pageSize: 10 },
  });

  return data;
};

const joinEvent = async (
  eventId: string,
  body: { comment: string; images: string[] | undefined }
) => {
  const { data } = await callApi({
    body,
    method: "POST",
    endpoint: `/web/a-event/join/${eventId}`,
  });

  return data;
};

const getEventParticipants = async ({
  eventId,
  page = 1,
  participant,
  pageSize = 10,
}: {
  page?: number;
  eventId: string;
  pageSize?: number;
  participant?: string;
}) => {
  const { data } = await callApi({
    query: { page, pageSize, term: participant },
    endpoint: `/web/a-event/${eventId}/participants`,
  });

  return data;
};

const reactEventParticipant = async (body: {
  event_id: string;
  participant_id: string;
  reaction: "like" | "dislike";
}) => {
  const { data } = await callApi({
    body,
    method: "POST",
    endpoint: "/a-event/react",
  });

  return data;
};

export {
  getEvent,
  getEvents,
  joinEvent,
  getEventParticipants,
  reactEventParticipant,
};
